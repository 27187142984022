import 'regenerator-runtime/runtime';

import React, { Suspense } from 'react';
import { SnackbarProvider } from 'notistack';

import LastWallThemeProvider from './themes/LastWallThemeProvider';
import { RISCProvider } from './risc/RISCContext';
import Centered from '../../components/card/Centered';

const ProviderStack = ({ children }) => (
  <LastWallThemeProvider>
    <RISCProvider>
      <SnackbarProvider>
        <Suspense fallback={<div />}>
          <Centered>{children}</Centered>
        </Suspense>
      </SnackbarProvider>
    </RISCProvider>
  </LastWallThemeProvider>
);

export default ProviderStack;
