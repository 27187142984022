import { publicKeyCredentialToJSON, base64ToBuffer } from './utils';
import type { Base64PublicKeyCredentialCreationOptions } from './types/types';

export default async (
  options: Base64PublicKeyCredentialCreationOptions,
): Promise<string> => {
  if (!window.PublicKeyCredential) {
    throw new Error('WebAuthn not supported on this browser');
  }

  const publicKey: PublicKeyCredentialCreationOptions = {
    ...options,
    challenge: base64ToBuffer(options.challenge),
    user: {
      ...options.user,
      id: base64ToBuffer(options.user.id),
    },
    excludeCredentials: options.excludeCredentials?.map(
      (excludeCredential) => ({
        ...excludeCredential,
        id: base64ToBuffer(excludeCredential.id),
      }),
    ),
  };

  const credential: PublicKeyCredential = (await navigator.credentials.create({
    publicKey,
  })) as PublicKeyCredential;

  let transports = [];
  let extensions = {};

  // Explicitly check functions exist on different browsers
  // @ts-ignore
  if (typeof credential.response.getTransports === 'function') {
    // @ts-ignore
    transports = credential.response.getTransports();
  }

  if (typeof credential.getClientExtensionResults === 'function') {
    extensions = publicKeyCredentialToJSON(
      credential.getClientExtensionResults(),
    );
  }

  const base64Credential = publicKeyCredentialToJSON(credential);
  return {
    ...base64Credential,
    extensions,
    transports,
  };
};
