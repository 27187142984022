// @ts-ignore
import lastwallLogo from '../../../assets/img/lw-logo.svg';
import stageQueryFragment from './stage/stageQuery.fragment';
import { updateStage } from './stage/stageStore';
import backgroundImage from '../../../assets/img/background.jpeg';

export const orgProfileQuery = `
  query($input: OrgProfileInput!) {
    orgProfile(input: $input) {
      authSessionSlug
      policyID
      background {
        name
        content
        type
        size
        slug
      }
      logo {
        name
        content
        type
        size
        slug 
      }
      logoURL
      backgroundURL
      serviceSignInURL
      pivDomain
      csrfToken

      enableTermsAndConditions
      termsAndConditionsMarkdown
      termsAndConditionsAcceptButtonText

      friction {
        type
        message
        timeout
      }

      ${stageQueryFragment}
    }
  }
`;

const defaultOrgProfile = {
  loaded: false,
  authSessionSlug: null,
  policyID: null,
  logoURL: lastwallLogo,
  backgroundURL: null,
  serviceSignInURL: null,
  pivDomain: null,
  csrfToken: null,
  enableTermsAndConditions: true,
  friction: {
    enabled: false,
    type: false,
    timeout: 15,
    message: 'Please wait',
  },
};

export const updateOrgProfile = (state, data) => {
  const { stage, ...orgProfile } = data.orgProfile;

  orgProfile.loaded = true;
  orgProfile.logoURL = orgProfile.logoURL || lastwallLogo;

  // Using a default bg for get org page but resetting to whatever
  // the org has it defined as once it's loaded.

  // data.orgProfile.backgroundURL is set to '' by
  // getBranding.ts until we are able to upload images to the db
  orgProfile.backgroundURL = data.orgProfile.backgroundURL || backgroundImage;

  const nextState = updateStage(state, { stage });

  return {
    ...nextState,
    orgProfile,
  };
};

export const getLogoURL = (orgProfile) => {
  const { logo, logoURL } = orgProfile;
  return logo ? logo.content : logoURL;
};

export default defaultOrgProfile;
