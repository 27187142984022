import React from 'react';

// material
import Typography from '@mui/material/Typography';

const PasswordResetNotAllowed = () => (
  <Typography>
    Password reset not allowed. Please contact your service administrator.
  </Typography>
);

export default PasswordResetNotAllowed;
