import React, { useEffect } from 'react';

import { useStateMachine } from 'little-state-machine';

import { useFetchGraphQL } from '../../core/hooks/apiFetchOptions';
import useNavigateToNext from '../../core/hooks/useNavigateToNext';
import stageQueryFragment from '../../core/store/stage/stageQuery.fragment';

import FrictionView from './Friction.view';

const FrictionPage = () => {
  const navigateToNext = useNavigateToNext();
  const { state } = useStateMachine() as any;
  const { friction } = state.orgProfile;

  const completeFrictionMutation = `
    mutation {
      completeFriction {
        ${stageQueryFragment}
      }
    }
  `;

  const completeFriction = useFetchGraphQL(
    async ({ fetchGraphQL }) => {
      const data = await fetchGraphQL({
        state,
        query: completeFrictionMutation,
      });

      navigateToNext(data.completeFriction);
    },
    [state],
  );

  useEffect(() => {
    completeFriction.run();
  }, [completeFriction.run]);

  return (
    <FrictionView
      {...{
        logoURL: state.orgProfile.logoURL,
        message: friction.message,
      }}
    />
  );
};

export default FrictionPage;
