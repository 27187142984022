// Polyfills
import 'core-js';
import 'react-app-polyfill/ie11';
import 'abortcontroller-polyfill/dist/polyfill-patch-fetch';
import 'mutationobserver-shim';

import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

// Support
import { ErrorBoundary } from 'react-error-boundary';

// API
import {
  StateMachineProvider,
  createStore,
  // DevTool,
} from 'little-state-machine';
import * as apiFetchOptions from './core/hooks/apiFetchOptions';

// Providers
import { reducer } from './core/appState/reducer';
import { StateProvider } from './core/appState/state';
import ProviderStack from './core/providers/ProviderStack';

// Custom
import Routes, { nonAuthSessionRoutes } from './core/routes/Routes';
import NotFound404Page from './core/notFound/NotFound404.page';
import ErrorPage from './core/error/Error.page';

// Stores
import login from './core/store/loginStore';
import orgProfile from './core/store/orgProfileStore';
import stage from './core/store/stage/stageStore';

const LASTWALL_STORAGE_NAME = '__LWS__';
// @ts-ignore
window.isProduction = process.env.NODE_ENV === 'production';

// Important this is reaching into LSM internals, not a pretty thing
sessionStorage.removeItem(LASTWALL_STORAGE_NAME);

createStore(
  {
    login,
    orgProfile,
    stage,
  },
  {
    name: LASTWALL_STORAGE_NAME,
    // since we get background and logo images as part of orgProfile,
    // stored in state it overflows the session storage
    persist: 'none',
  },
);

// Global State intialize
const intialState = {
  orgProfileError: {
    message: null,
  },
};

const App = () => {
  apiFetchOptions.initConfig();

  return (
    <StateProvider intialState={intialState} reducer={reducer}>
      <StateMachineProvider>
        <Router>
          <Switch>
            {nonAuthSessionRoutes()}

            <Route path="/">
              <ProviderStack>
                <ErrorBoundary fallbackRender={ErrorPage as any}>
                  {/* {!window.isProduction && <DevTool />} */}
                  <Routes />
                </ErrorBoundary>
              </ProviderStack>
            </Route>

            <Route path="*">
              <ProviderStack>
                <NotFound404Page />
              </ProviderStack>
            </Route>
          </Switch>
        </Router>
      </StateMachineProvider>
    </StateProvider>
  );
};

ReactDOM.render(<App />, document.getElementById('app'));
