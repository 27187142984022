import React from 'react';

import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import Icon from '@mui/material/Icon';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

import TwoFactorSelector from '../two-factor-selector/TwoFactorSelector';
import CardWithLogo from '../../components/card/CardWithLogo';

const TwoFactorPIVView = ({
  error,
  factors,
  onSubmit = () => {},
  isPending,
  logoURL,
}) => {
  const tooltip = `\
    Your CAC/PIV holds up to 4 digital certificates.
    The CAC/PIV ID certificate is used to authenticate. All other certificates will be rejected.
    If you require assistance, please contact your IT support.\
  `;

  const isSupported = true;

  return (
    <Box>
      <CardWithLogo logoURL={logoURL}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
            marginBottom: 5,
          }}
        >
          <Typography variant="h2" paragraph>
            {isPending && 'Awaiting '}
            CAC/PIV Authentication
          </Typography>
          {!isSupported && (
            <Typography variant="body1" paragraph color="error">
              CAC/PIV is not available on your platform. Please use a different
              credential.
            </Typography>
          )}
          {isSupported && (
            <>
              {!isPending && (
                <>
                  <Typography variant="body1" paragraph>
                    Insert your CAC/PIV card and press continue.
                    <Tooltip title={tooltip} aria-label="help">
                      <Icon fontSize="inherit">help_outline</Icon>
                    </Tooltip>
                  </Typography>

                  <Button
                    variant="contained"
                    color="primary"
                    onClick={onSubmit}
                  >
                    Continue with CAC/PIV
                  </Button>

                  {error && <Typography color="error">{error}</Typography>}
                </>
              )}
              {isPending && (
                <>
                  <CircularProgress
                    size={100}
                    sx={{
                      marginTop: 2,
                      marginBottom: 4,
                    }}
                  />
                  <Typography variant="body1" paragraph>
                    Please authorize your CAC/PIV credentials
                  </Typography>
                  <Button variant="outlined" onClick={onSubmit}>
                    Retry
                  </Button>
                </>
              )}
            </>
          )}
          <TwoFactorSelector
            factors={factors.filter((x) => x.type !== 'PIV')}
          />
        </Box>
      </CardWithLogo>
    </Box>
  );
};

export default TwoFactorPIVView;
