import React from 'react';

import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';

const KillSwitchView = ({
  onSubmit = () => {},
  error = null,
  isPending = false,
  isSettled = false,
}) => (
  <Card>
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        mt: 2,
        mb: 2,
        ml: 4,
        mr: 4,
        height: 300,
        width: 400,
      }}
    >
      <Typography variant="h6" paragraph>
        {isPending && !error && 'Executing '}
        Kill Switch
        {isSettled && !error && ' Completed Successfully'}
        {error && ' Has Errored'}
      </Typography>
      {isPending && error == null && (
        <CircularProgress
          sx={{
            mt: 2,
            mb: 4,
          }}
          size={100}
        />
      )}
      {isSettled && !error && (
        <>
          <Box
            sx={{
              '& i': {
                mb: 2,
                fontSize: 50,
                color: '#388e3c',
              },
            }}
          >
            <i className="material-icons">done</i>
          </Box>
        </>
      )}
      <Typography
        variant="body1"
        paragraph
        color={error ? 'error' : 'textPrimary'}
      >
        {error}
        {!isPending &&
          !isSettled &&
          !error &&
          "Please confirm you wish to lock the user's account."}
        {!error && isPending && 'Please do not close this tab.'}
        {!error && isSettled && 'You may now safely close this tab.'}
      </Typography>
      {!isPending && !isSettled && !error && (
        <Button variant="contained" onClick={onSubmit} color="primary">
          Confirm
        </Button>
      )}
    </Box>
  </Card>
);

export default KillSwitchView;
