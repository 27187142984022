import React from 'react';
import { Button, Typography } from '@mui/material';
import CardWithLogo from '../components/card/CardWithLogo';

type IALSetupViewProps = {
  logoURL?: string | URL;
  qrcode?: string;
  clickAction: () => void;
};

const IalSetupView = ({ logoURL, qrcode, clickAction }: IALSetupViewProps) => (
  <CardWithLogo logoURL={logoURL}>
    <Typography variant="h1" align="center">
      Confirm your Identity
    </Typography>
    <Typography variant="h2" align="center">
      Scan the QR code with the Lastwall mobile app to initiate identity
      proofing process.
    </Typography>
    <img src={qrcode} alt="qr code" />
    <Typography variant="body1" align="center">
      Please continue after completing identity proofing.
    </Typography>
    <Button
      sx={{
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: 2,
        marginBottom: 5,
      }}
      color="primary"
      variant="contained"
      onClick={clickAction}
    >
      Continue
    </Button>
  </CardWithLogo>
);
export default IalSetupView;
