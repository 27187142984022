import React, { createContext, useContext, useReducer } from 'react';
import { Reducer } from './reducer';

export const StateContext = createContext<any>({});

interface Props {
  children: any;
  reducer: Reducer;
  intialState: any;
}

export const StateProvider: React.FC<Props> = ({
  reducer,
  intialState,
  children,
}) => (
  <StateContext.Provider value={useReducer(reducer, intialState)}>
    {children}
  </StateContext.Provider>
);

export const useStateValue = () => useContext(StateContext);
