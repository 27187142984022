import React, { useContext } from 'react';

import { useStateMachine } from 'little-state-machine';
import * as Webauthn from '@lastwall/webauthn-client';

import useNavigateToNext from '../../core/hooks/useNavigateToNext';
import { useFetchGraphQL } from '../../core/hooks/apiFetchOptions';
import stageQueryFragment from '../../core/store/stage/stageQuery.fragment';

import TwoFactorWebAuthnView from './TwoFactorWebAuthn.view';
import RISCContext from '../../core/providers/risc/RISCContext';

const TwoFactorWebAuthnPage = () => {
  const navigateToNext = useNavigateToNext();

  const { getRiscData, stopRisc } = useContext(RISCContext);

  const { state } = useStateMachine() as any;
  const { primary, factors } = state.stage;

  const createChallengeMutation = `
    mutation($input: CreateWebAuthnLoginChallengeInput!) {
      createWebAuthnLoginChallenge(input: $input) {
        id
        requestOptions
      }
    }
  `;

  const tryLoginMutation = `
    mutation($input: TryWebAuthnLoginInput!) {
      tryWebAuthnLogin(input: $input) {
        ${stageQueryFragment}
      }
    }
  `;

  const authorize: any = useFetchGraphQL(
    async ({ args, fetchGraphQL }) => {
      const { values } = args[0];

      const challengeRes = await fetchGraphQL({
        state,
        query: createChallengeMutation,
        variables: {
          input: {
            ...values,
          },
        },
      });

      const { requestOptions, id: challengeID } =
        challengeRes.createWebAuthnLoginChallenge;

      let assertion;
      try {
        // eslint-disable-next-line prefer-const
        assertion = await Webauthn.get(requestOptions);
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
        throw new Error(
          'Authentication Failed. ' +
            'Please contact your systems administrator to register this device for WebAuthn.',
        );
      }

      const riscData = await getRiscData();

      const tryLoginRes = await fetchGraphQL({
        state,
        query: tryLoginMutation,
        variables: {
          input: {
            ...values,
            webAuthnCredential: {
              challengeID,
              assertion,
              riscData: JSON.stringify(riscData),
            },
          },
        },
      });

      // stop risc from collecting data to avoid console.log errors
      stopRisc(true);

      navigateToNext(tryLoginRes.tryWebAuthnLogin);
    },
    [state],
  );

  return (
    <TwoFactorWebAuthnView
      {...{
        logoURL: state.orgProfile.logoURL,
        error: authorize.error?.message,
        pending: authorize.isPending,
        factors,
        primary,
        onSubmit: authorize.run,
        defaultValues: { email: state.login.username },
      }}
    />
  );
};

export default TwoFactorWebAuthnPage;
