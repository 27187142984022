import React from 'react';

import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import CardWithLogo from '../../components/card/CardWithLogo';

const SuccessView = ({ logoURL, message, error }) => (
  <CardWithLogo logoURL={logoURL}>
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      {error == null && <CircularProgress size={100} />}
      <Typography
        variant={error ? 'h6' : 'body1'}
        paragraph
        color={error ? 'error' : 'textPrimary'}
        sx={{
          mt: 4,
          mb: 5,
          ml: 2,
          mr: 2,
        }}
      >
        {error || message}
      </Typography>
    </Box>
  </CardWithLogo>
);

export default SuccessView;
