/* eslint-disable react/require-default-props */
import React from 'react';

import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

import CardWithLogo from '../components/card/CardWithLogo';

/**
 * Generates a logout message for display on the logout page.
 */
export const logoutMessage = (
  logoutReason: string | null | undefined,
): string => {
  if (logoutReason === 'sessionTimeout') {
    return 'You were logged out of your session due to inactivity';
  }

  return 'You have been successfully logged out';
};

type LogoutViewProps = {
  serviceSignInURL?: string | URL;
  logoURL?: string | URL;
  logoutReason?: string | null;
};

const LogoutView = ({
  serviceSignInURL,
  logoURL,
  logoutReason,
}: LogoutViewProps) => (
  <CardWithLogo logoURL={logoURL}>
    <Typography variant="h2" align="center" paragraph>
      {logoutMessage(logoutReason)}
    </Typography>
    {serviceSignInURL && (
      <Button
        sx={{ mt: 2 }}
        color="primary"
        component="a"
        href={serviceSignInURL}
      >
        Log in again
      </Button>
    )}
  </CardWithLogo>
);

export default LogoutView;
