import React from 'react';

import ErrorPage from '../error/Error.page';

const InactivePage = () => (
  <ErrorPage
    error={{
      message:
        'If you believe this is a mistake, please contact your administrator',
    }}
    title="Inactive Service:"
  />
);

export default InactivePage;
