import { useMemo } from 'react';
import config from './config';
import useAsync from './useAsync';
import UnauthorizedError from './UnauthorizedError';

export const fetchGraphQL = async ({
  query,
  variables = null,
  options = null,
  state = null,
  signal,
}) => {
  const { graphqlURL, apiPostOptions } = config;

  const res = await fetch(graphqlURL(), {
    ...(options || apiPostOptions(state)),
    signal,
    body: JSON.stringify({
      query,
      variables,
    }),
  });

  let json;

  // Handle non JSON responses
  try {
    json = await res.json();
  } catch (err) {
    // This usually comes from the WAF blocking a request
    if (res.status === 403) {
      throw new Error('Error: Request Forbidden. Check input and try again.');
    }
    // We should never be recieving non-json from our graphQL endpoints
    // eslint-disable-next-line no-console
    console.warn(
      'WARNING: Recieved unexpected HTML document from graphQL endpoint: ',
      res.body,
    );
    throw new Error('Error: Recieved unexpected HTML document from backend');
    // eslint-disable-next-line
  } finally {
  }

  // Handle unsuccessful responses and graphQL errors
  if (!res.ok || json?.errors) {
    const message =
      json?.errors[0]?.message || 'Network Error. Please try again.';
    let error;
    if (res.status === 403) {
      error = new UnauthorizedError(
        message ?? 'Error: Requested action is forbidden',
      );
    } else {
      error = new Error(message);
    }
    // eslint-disable-next-line
    console.error(json?.errors[0] || error);

    throw error;
  }

  return json.data;
};

export const useFetchGraphQL = (cb, inputs) => {
  const asyncArgs = useMemo(() => {
    return {
      deferFn: (args, { signal }) => {
        try {
          return cb({
            fetchGraphQL: (opts) =>
              fetchGraphQL({
                signal,
                ...opts,
              }),
            signal,
            args,
          });
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error(error);
          throw error;
        }
      },
    };
  }, [cb, ...inputs]);

  const asyncHook = useAsync(asyncArgs);

  return asyncHook;
};
