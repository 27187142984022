import React, { useEffect, useState } from 'react';

import { useStateMachine } from 'little-state-machine';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router-dom';

import SMSSetupView from './SMSSetup.view';
import { useFetchGraphQL } from '../../core/hooks/apiFetchOptions';

const SMSSetupPage = () => {
  const history = useHistory();
  const snackbar = useSnackbar();
  const { state } = useStateMachine();

  const initialStepperState = {
    activeStep: 0,
  };
  const [{ activeStep }, setStepperState] = useState(initialStepperState);

  const createPhoneChannelMutation = `
    mutation($input: CreatePhoneChannelInput!) {
      createPhoneChannel(input: $input) {
        id
      }
    }
  `;

  const createPhoneChannel = useFetchGraphQL(
    async ({ args, fetchGraphQL }) => {
      const { phoneNumber } = args[0].values;

      await fetchGraphQL({
        state,
        query: createPhoneChannelMutation,
        variables: {
          input: {
            phoneNumber,
          },
        },
      });

      setStepperState({
        activeStep: 1,
      });
    },
    [state],
  );

  const verifyPhoneChannelMutation = `
    mutation($input: VerifyPhoneChannelInput!) {
      verifyPhoneChannel(input: $input) {
        id
      }
    }
  `;

  const verifyPhoneChannel = useFetchGraphQL(
    async ({ args, fetchGraphQL }) => {
      setStepperState({
        activeStep: 2,
      });

      const { code } = args[0].values;

      await fetchGraphQL({
        state,
        query: verifyPhoneChannelMutation,
        variables: {
          input: {
            code,
          },
        },
      });

      snackbar.enqueueSnackbar('SMS registration successful!');
      history.push('/two-factor-setup');
    },
    [state],
  );

  useEffect(() => {
    if (verifyPhoneChannel.error != null) {
      setStepperState({
        activeStep: 1,
      });
    }
  }, [verifyPhoneChannel.isPending, verifyPhoneChannel.error]);

  const onSubmit = async ({ values }) => {
    if (activeStep === 0) {
      await createPhoneChannel.run({ values });
    } else if (activeStep === 1) {
      await verifyPhoneChannel.run({ values });
    } else {
      throw new Error('Invalid stepper activeStep');
    }
  };

  return (
    <SMSSetupView
      {...{
        createPhoneChannelError: createPhoneChannel.error?.message,
        verifyPhoneChannelError: verifyPhoneChannel.error?.message,
        isSubmittingCreate: createPhoneChannel.isPending,
        isSubmittingVerify: verifyPhoneChannel.isPending,
        activeStep,
        onSubmit,
        onBackClick: () => {
          verifyPhoneChannel.cancel();
          setStepperState(initialStepperState);
        },
      }}
    />
  );
};

export default SMSSetupPage;
