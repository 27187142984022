import React from 'react';

import TextField, { TextFieldProps } from '@mui/material/TextField';

const LastWallTextField = (props: TextFieldProps) => {
  const { error, helperText, ...otherProps } = props;

  return (
    <TextField
      {...otherProps}
      error={!!error}
      helperText={helperText ?? error}
    />
  );
};

LastWallTextField.defaultProps = {
  variant: 'outlined',
  type: 'text',
  autoComplete: 'off',
  fullWidth: true,
  autoFocus: false,
  InputLabelProps: {
    shrink: true,
  },
};

export default LastWallTextField;
