export interface Stage {
  state: string;
  forceOnboarding: boolean;
  numRequired: number;
  nextPolicyRequirements: [FactorVerification];
  factors: [Factor];
  factorAuthorizationTokens?: [FactorAuthorizationToken];
}

export interface FactorVerification {
  type: string;
  verified: boolean;
}

export interface Factor {
  id: string;
  type: string;
  passed: boolean;
  failed: number;
  extras?: any;
}

// A token that can be send to the server to authorize a pre-determined factor on the given user
export interface FactorAuthorizationToken {
  id: string;
  username: string;
}

// export interface Authorization {
//   samlACS: string,
//   samlResponse: any,
//   relayState?: boolean,
// }

export const updateStage = (state, payload) => {
  const { stage, resetRequired } = payload;

  return {
    ...state,
    stage,
    resetRequired: resetRequired == null ? state.resetRequired : resetRequired,
  };
};

export const updateForceOnboarding = (state, payload) => {
  const { forceOnboarding } = payload;
  return {
    ...state,
    stage: {
      ...state.stage,
      forceOnboarding,
    },
  };
};

const initialStage = null;

export default initialStage;
