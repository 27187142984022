import React from 'react';
import { useHistory } from 'react-router-dom';

import { useStateMachine } from 'little-state-machine';
import { useFetchGraphQL } from '../../core/hooks/apiFetchOptions';
import PasswordView from './PasswordReset.view';
import useNavigateToNext from '../../core/hooks/useNavigateToNext';
import stageQueryFragment from '../../core/store/stage/stageQuery.fragment';

const PasswordResetPage = () => {
  const history = useHistory();
  const navigateToNext = useNavigateToNext();
  const { state } = useStateMachine() as any;

  const resetPasswordMutation = `
    mutation($input: ResetPasswordInput!) {
      resetPassword(input: $input) {
        ${stageQueryFragment}
        enrollmentComplete
      }
    }
  `;

  const resetPassword: any = useFetchGraphQL(
    async ({ args, fetchGraphQL }) => {
      const { password } = args[0].values;

      const data = await fetchGraphQL({
        state,
        query: resetPasswordMutation,
        variables: {
          input: {
            password,
          },
        },
      });
      navigateToNext(data.resetPassword);
    },
    [state],
  );

  const onBack = () => {
    history.push('./');
  };

  return (
    <PasswordView
      {...{
        error: resetPassword.error?.message,
        onBack,
        onSubmit: resetPassword.run,
        isSubmitting: resetPassword.isPending,
        defaultValues: state.login,
      }}
    />
  );
};

export default PasswordResetPage;
