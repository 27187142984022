import React, { useMemo } from 'react';

// import useMediaQuery from '@mui/material/useMediaQuery'
import CssBaseline from '@mui/material/CssBaseline';
import {
  createTheme,
  ThemeProvider,
  StyledEngineProvider,
  responsiveFontSizes,
  adaptV4Theme,
} from '@mui/material/styles';

import DarkModeContext from './DarkModeContext';

const base = {
  typography: {
    fontFamily: ['sans-serif'],
    fontSize: 16,
    body1: {
      fontSize: '1rem',
    },
    body2: {
      fontSize: '0.875rem',
    },
    h1: {
      fontSize: '2rem',
    },
    h2: {
      fontSize: '1.25rem',
    },
  },
};

const lightTheme = {
  ...base,
  palette: {
    mode: 'light',
    primary: {
      main: '#0c798a',
      light: '#78abbc',
      dark: '#246e8c',
    },
    secondary: {
      main: '#4291ab',
      light: '#78abbc',
      dark: '#0c798a',
    },
  },
};

const darkTheme = {
  ...base,
  palette: {
    mode: 'dark',
    primary: {
      main: '#246e8c',
      light: '#4291ab',
      dark: '#34576d',
    },
    secondary: {
      main: '#4291ab',
      light: '#78abbc',
      dark: '#0c798a',
    },
  },
};

const LastWallThemeProvider = ({ children }) => {
  // const browserDarkMode = useMediaQuery('(prefers-color-scheme: dark)')
  // console.log({ browserDarkMode })

  // const savedDarkModePreference = useMemo(() => (
  //   JSON.parse(localStorage.getItem('darkMode'))
  // ), [])

  // const [localDarkMode, setDarkMode] = useState(savedDarkModePreference)
  // const darkMode = localDarkMode || browserDarkMode

  // const toggleDarkMode = () => {
  //   localStorage.setItem('darkMode', JSON.stringify(!darkMode))
  //   setDarkMode(!darkMode)
  // }

  // Light/dark mode is hardcoded to light mode until we get the time to debug dark and light modes
  const darkMode = false;
  const toggleDarkMode = () => {};

  const theme = useMemo(
    // @ts-ignore
    () =>
      responsiveFontSizes(
        createTheme(adaptV4Theme(darkMode ? darkTheme : lightTheme)),
      ),
    [darkMode],
  );

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <DarkModeContext.Provider value={{ darkMode, toggleDarkMode }}>
          <>
            <CssBaseline />
            {children}
          </>
        </DarkModeContext.Provider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default LastWallThemeProvider;
