import React, {
  useContext,
  createRef,
  useState,
  useEffect,
  useLayoutEffect,
} from 'react';

// Support
import { useForm } from 'react-hook-form';
import { useStateMachine } from 'little-state-machine';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import InputAdornment from '@mui/material/InputAdornment';
import FadeIn from 'react-fade-in';
import useNavigateToNext from '../../core/hooks/useNavigateToNext';
import stageQueryFragment from '../../core/store/stage/stageQuery.fragment';
import RISCContext, {
  useRISCInputRef,
} from '../../core/providers/risc/RISCContext';

// Store
import { updateLogin } from '../../core/store/loginStore';

// API
import { useFetchGraphQL } from '../../core/hooks/apiFetchOptions';

// Material

// Custom
import TwoFactorSelector from '../two-factor-selector/TwoFactorSelector';
import LastWallTextField from '../../components/textField/LastWallTextField';

// Other

// Style
import { loginAndPasswordCommonStyles } from '../../components/styles/Login.styles';

const Password = () => {
  const { state, actions } = useStateMachine({ updateLogin }) as any;
  const { factors } = state.stage;

  const navigateToNext = useNavigateToNext();

  // Check if browser is any of the browsers that do not support TextField
  // adornments.
  const ua = navigator.userAgent;
  const isPanGlobalProtect = ua.toLowerCase().includes('globalprotect');
  const isSalesforce = ua.toLowerCase().includes('salesforce');
  const hideAdornments = isPanGlobalProtect || isSalesforce;

  const [hidePasssword, setHidePasssword] = useState(true);

  const { getRiscData, stopRisc } = useContext(RISCContext);

  const emailRef: any = createRef();
  const passwordRef: any = createRef();
  useRISCInputRef(passwordRef, true);

  const tryPasswordMutation = `
    mutation($input: TryPasswordLoginInput!) {
      tryPasswordLogin(input: $input) {
        ${stageQueryFragment}
        enrollmentComplete
      }
    }
  `;

  const tryPassword: any = useFetchGraphQL(
    async ({ args, fetchGraphQL }) => {
      const { values } = args[0];

      const riscData = await getRiscData();

      const data = await fetchGraphQL({
        state,
        query: tryPasswordMutation,
        variables: {
          input: {
            ...values,
            riscData: JSON.stringify(riscData),
          },
        },
      });

      // stop risc from collecting data to avoid console.log errors
      stopRisc(true);

      navigateToNext(data.tryPasswordLogin);
    },
    [state],
  );

  const isSubmitting = tryPassword.isPending;
  const defaultValues = state.login;
  const onSubmit = tryPassword.run;

  const {
    handleSubmit,
    register,
    errors,
    setError,
    formState,
    reset,
    getValues,
  } = useForm({ defaultValues });

  const passwordError = errors.password?.message ?? null;
  const emailError = errors.email?.message ?? null;

  useEffect(() => {
    reset({ email: state.login.username, password: '' });
  }, [reset, state.login.username]);

  // Save the username in the state when unmounting effect
  useLayoutEffect(
    () => () => {
      const { email } = getValues();
      actions.updateLogin({ username: email });
    },
    [getValues, actions, reset],
  );

  useLayoutEffect(() => {
    emailRef.current.focus();
  }, []);

  useEffect(() => {
    if (!isSubmitting) {
      setError('password', {
        type: 'server',
        message: tryPassword.error?.message,
        shouldFocus: true,
      });
    }
  }, [tryPassword.error?.message, isSubmitting]);

  useEffect(() => {
    if (!isSubmitting) {
      setError('email', {
        type: 'server',
        message: tryPassword.error?.message,
        shouldFocus: true,
      });
    }
  }, [tryPassword.error?.message, isSubmitting]);

  return (
    <Box sx={{ ...loginAndPasswordCommonStyles.formContainer }}>
      <form
        // @ts-ignore
        onSubmit={handleSubmit((values) => onSubmit({ values, setError }))}
      >
        <Box pb={4}>
          <FadeIn delay={100} transitionDuration={500}>
            <Box mt={2}>
              <LastWallTextField
                label="Enter your email"
                variant="outlined"
                InputProps={{
                  name: 'email',
                  endAdornment: hideAdornments ? undefined : (
                    <InputAdornment position="end">
                      <i className="material-icons">account_circle</i>
                    </InputAdornment>
                  ),
                  sx: {
                    WebkitUserSelect: 'text',
                    userSelect: 'text',
                  },
                }}
                inputRef={(e) => {
                  emailRef.current = e;
                  register(e, {
                    required: 'Required',
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                      message: 'Malformed email address',
                    },
                  });
                }}
                disabled={isSubmitting || formState?.isSubmitting}
                error={emailError}
              />
            </Box>

            <Box mt={2}>
              <LastWallTextField
                name="password"
                label="Enter your password"
                type={hidePasssword ? 'password' : 'text'}
                InputProps={{
                  endAdornment: hideAdornments ? undefined : (
                    <InputAdornment position="end">
                      <i
                        style={{ cursor: 'pointer' }}
                        className="material-icons"
                        onClick={() => setHidePasssword(!hidePasssword)}
                        onKeyPress={(e) =>
                          e.keyCode === 13 && setHidePasssword(!hidePasssword)
                        }
                        role="button"
                        tabIndex={0}
                      >
                        {hidePasssword ? 'visibility' : 'visibility_off'}
                      </i>
                    </InputAdornment>
                  ),
                }}
                inputRef={(e) => {
                  passwordRef.current = e;
                  register(e, {
                    required: 'Required',
                  });
                }}
                disabled={isSubmitting || formState?.isSubmitting}
                error={passwordError}
              />
            </Box>

            <Box
              mt={2}
              sx={{
                display: 'flex',
                direction: 'row',
                justifyContent: 'center',
              }}
            >
              <Button
                color="primary"
                variant="contained"
                type="submit"
                disabled={isSubmitting || formState?.isSubmitting}
                sx={{ width: 231 }}
              >
                SUBMIT
              </Button>
            </Box>

            <Box
              mt={3}
              sx={{
                display: 'flex',
                direction: 'row',
                justifyContent: 'center',
              }}
            >
              <TwoFactorSelector
                factors={factors.filter(
                  (factor) => factor.type.toLowerCase() !== 'password',
                )}
              />
            </Box>
          </FadeIn>
        </Box>
      </form>
    </Box>
  );
};

export default Password;
