import { publicKeyCredentialToJSON, base64ToBuffer } from './utils';
import type { Base64PublicKeyCredentialRequestOptions } from './types/types';

export default async (
  options: Base64PublicKeyCredentialRequestOptions,
): Promise<string> => {
  if (!window.PublicKeyCredential) {
    throw new Error('WebAuthn not supported on this browser');
  }

  const publicKey: PublicKeyCredentialRequestOptions = {
    ...options,
    challenge: base64ToBuffer(options.challenge),
    allowCredentials: options.allowCredentials.map((allowedCredential) => ({
      ...allowedCredential,
      id: base64ToBuffer(allowedCredential.id),
    })),
  };

  const credential: PublicKeyCredential = (await navigator.credentials.get({
    publicKey,
  })) as PublicKeyCredential;

  let extensions = {};
  // Explicitly check functions exist on different browsers
  if (typeof credential.getClientExtensionResults === 'function') {
    extensions = publicKeyCredentialToJSON(
      credential.getClientExtensionResults(),
    );
  }

  const base64Credential = publicKeyCredentialToJSON(credential);
  return {
    ...base64Credential,
    extensions,
  };
};
