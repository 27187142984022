import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';

// import MUILink from '@mui/material/Link'
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Icon from '@mui/material/Icon';
import TextField from '@mui/material/TextField';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';

import { Box, InputAdornment } from '@mui/material';

// @ts-ignore
import appStoreBadge from '../../../assets/img/appstorebadge.svg';
// @ts-ignore
import playStoreBadge from '../../../assets/img/playstorebadge.png';

export interface TOTPSetupProps {
  onSubmit?: (values: any) => Promise<void>;
  onBackClick?: () => void;
  isSubmitting?: boolean;
  activeStep?: number;
  qrCodeImage?: string;
  otpAuthURL?: string;
  base32?: string;
  createTOTPCodeError?: string;
  verifyTOTPCodeError?: string;
}

const isIOS = () =>
  [
    'iPad Simulator',
    'iPhone Simulator',
    'iPod Simulator',
    'iPad',
    'iPhone',
    'iPod',
  ].includes(navigator.platform) ||
  // iPad on iOS 13 detection
  (navigator.userAgent.includes('Mac') && 'ontouchend' in document);

const styles = {
  card: {
    paddingLeft: 2,
    paddingRight: 2,
    paddingTop: 3,
    paddingBottom: 3,
    marginBottom: 2,
    width: 430,
    xs: {
      // 360px is the smallest viewport width of popular Android phones atm
      width: 360,
      paddingLeft: 2,
      paddingRight: 2,
      paddingTop: 1,
      paddingBottom: 2,
    },
  },
  // appleStoreBadge: {
  //   height: 45px;
  //   padding: 8px;
  // },

  /*
    Using a callback should not be required here since MUI should use the theme
    spacing multiple by default. But, for some reason the images will not render
    at the proper size unless the theme.spacing callback is used.
  */
  appStoreBadge: {
    height: (theme) => theme.spacing(5),
    mr: (theme) => theme.spacing(2),
  },
};

const TOTPSetupView = ({
  onSubmit = async () => {},
  onBackClick = () => {},
  isSubmitting = false,
  activeStep = 0,
  qrCodeImage,
  otpAuthURL,
  base32,
  createTOTPCodeError,
  verifyTOTPCodeError,
}: TOTPSetupProps) => {
  const userAgent = navigator.userAgent.toLowerCase();
  const isAndroid = userAgent.indexOf('android') > -1;
  const isMobile = isAndroid || isIOS();

  const [isRevealed, setReveal] = useState(false);
  const [hideTOTPCode, setTOTPCode] = useState(true);

  // Check if browser is any of the browsers that do not support TextField
  // adornments.
  const ua = navigator.userAgent;
  const isPanGlobalProtect = ua.toLowerCase().includes('globalprotect');
  const isSalesforce = ua.toLowerCase().includes('salesforce');
  const hideAdornments = isPanGlobalProtect || isSalesforce;

  const { handleSubmit, register, errors, setError, formState } = useForm();

  useEffect(() => {
    if (!isSubmitting && verifyTOTPCodeError) {
      setError('code', {
        type: 'server',
        message: verifyTOTPCodeError,
        shouldFocus: true,
      });
    }
  }, [verifyTOTPCodeError, isSubmitting]);

  const codeRef: any = useRef();

  useLayoutEffect(() => {
    if (activeStep === 1) {
      codeRef.current?.focus();
    }
  }, [activeStep]);

  if (createTOTPCodeError) {
    return (
      <Card sx={styles.card}>
        <Typography variant="body1" color="error" paragraph>
          {createTOTPCodeError}
        </Typography>
      </Card>
    );
  }

  return (
    <form
      // @ts-ignore
      onSubmit={handleSubmit((values) => onSubmit({ values, setError }))}
    >
      <Card sx={styles.card}>
        <Icon
          fontSize="large"
          sx={{
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
          }}
        >
          phonelink_lock
        </Icon>
        <Stepper activeStep={activeStep} orientation="vertical">
          <Step key="phone_number">
            <StepLabel>Scan the QR</StepLabel>
            <StepContent>
              <Box sx={{ mt: 2 }}>
                <img alt="qr code" src={qrCodeImage} />
              </Box>

              {isMobile && (
                <Typography variant="body2" paragraph>
                  <Button
                    variant="outlined"
                    component="a"
                    href={otpAuthURL}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Open in Authenticator
                  </Button>
                </Typography>
              )}
              {!isRevealed && (
                <Button
                  sx={{ mb: 2 }}
                  onClick={() => setReveal(true)}
                  variant="outlined"
                >
                  Reveal Setup Key
                </Button>
              )}
              {isRevealed && (
                <Typography variant="body1" paragraph>
                  {`Setup Key: ${base32}`}
                </Typography>
              )}

              <Typography variant="body2" paragraph>
                A common application for TOTP is Google Authenticator
              </Typography>
              <Box>
                <a
                  href="https://itunes.apple.com/ca/app/google-authenticator/id388497605?mt=8"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Box
                    component="img"
                    alt="Apple App Store"
                    sx={styles.appStoreBadge}
                    src={appStoreBadge}
                  />
                </a>
                <a
                  href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Box
                    component="img"
                    alt="Google Play Store"
                    sx={styles.appStoreBadge}
                    src={playStoreBadge}
                  />
                </a>
              </Box>

              <Button
                color="primary"
                type="submit"
                variant="contained"
                size="small"
                sx={{ mt: 2 }}
                disabled={formState.isSubmitting}
              >
                Next
              </Button>
            </StepContent>
          </Step>
          <Step key="sms_code">
            <StepLabel>Enter TOTP code</StepLabel>
            <StepContent>
              <TextField
                sx={{ mt: 2 }}
                name="code"
                label="6 Digit code*"
                placeholder="123456"
                variant="outlined"
                autoComplete="off"
                type={hideTOTPCode ? 'password' : 'text'}
                autoFocus
                fullWidth
                InputProps={{
                  autoFocus: true,
                  endAdornment: hideAdornments ? (
                    <></>
                  ) : (
                    <InputAdornment position="end">
                      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
                      <i
                        style={{ cursor: 'pointer' }}
                        className="material-icons"
                        onClick={() => setTOTPCode(!hideTOTPCode)}
                        role="button"
                        tabIndex={0}
                      >
                        {hideTOTPCode ? 'visibility' : 'visibility_off'}
                      </i>
                    </InputAdornment>
                  ),
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                inputRef={(e) => {
                  codeRef.current = e;
                  register(e, {
                    required: 'Required',
                    maxLength: {
                      value: 8,
                      message: 'Must be less then 9 digits',
                    },
                    pattern: {
                      value: /^\d+$/,
                      message: 'Invalid 2FA Code',
                    },
                  });
                }}
                error={errors.code != null}
                helperText={errors.code?.message}
              />

              <Box sx={{ mt: 2 }}>
                <Button
                  variant="outlined"
                  size="small"
                  disabled={formState.isSubmitting}
                  onClick={onBackClick}
                  sx={{ mr: 2 }}
                >
                  Back
                </Button>

                <Button
                  color="primary"
                  size="small"
                  type="submit"
                  variant="contained"
                  disabled={formState.isSubmitting}
                >
                  Next
                </Button>
              </Box>
            </StepContent>
          </Step>
          <Step key="verify_sms_code">
            <StepLabel>Verify access code</StepLabel>
            <StepContent sx={{ textAlign: 'center' }}>
              <Box sx={{ mt: 2 }}>
                <CircularProgress size={100} />
              </Box>
            </StepContent>
          </Step>
        </Stepper>
      </Card>
      <Button variant="contained" component={Link} to="/two-factor-setup">
        Start Over
      </Button>
    </form>
  );
};

export default TOTPSetupView;
