/* eslint-disable */

import Fingerprint2 from '@fingerprintjs/fingerprintjs';

let localStorage;

try {
  localStorage = window.localStorage;
} catch {
  console.warn(
    'RISC was unable to open window.localStorage. User is probably running chromium.',
  );
}

const RiscDataCollector = function () {
  const self = this;
  /* eslint-enable no-unused-vars */
  const maxKeyEvents = 1000;
  let _debug = false;

  const _baseTimestamp = new Date().getTime();
  // Iframe url must be accurate to post risc data to the iframe
  const _iframeUrl = 'https://iframe.lastwall.com:8080/iframe';
  // Url to submit risc data
  const _riscDataSubmissionUrl = 'https://riscData.lastwall.com:8080/';
  let _riscData = {
    cadence: {
      key_event: [],
      control_keystroke: [],
      mouse_event: [],
      lastwall_key_event: [],
      compression_version: 1,
    },
    generic: {},
    fingerprint: {},
    lastwall: {
      base_timestamp: _baseTimestamp,
      exceptions: [],
      risc_module: 'browser',
    },
  };

  let attachEventListener;

  if (typeof document.addEventListener !== 'undefined') {
    attachEventListener = 'addEventListener';
  } else if (typeof document.attachEvent !== 'undefined') {
    attachEventListener = 'attachEvent';
  } else {
    _riscData.lastwall.exceptions.push(
      'Unsupported browser - cannot attach events to username/password fields!',
    );
  }

  let inputFieldsWatched = [];

  // This is to help keep track if autofilled was captured
  let _autofillSet = false;
  // Help us track autofill by comparing captured values
  const _inputLengths = {};
  const _allowClipboard = false;
  let _riscField = null;
  // let _lastwallIframe
  const _specialKeys = {
    8: 'Backspace',
    9: 'Tab',
    12: 'Num Lock/Clear',
    13: 'Enter',
    16: 'Shift',
    17: 'Control',
    18: 'Alt',
    19: 'Pause/Break',
    20: 'CapsLock',
    27: 'Esc',
    33: 'Page Up',
    34: 'Page Down',
    35: 'End',
    36: 'Home',
    37: 'Left Arrow',
    38: 'Up Arrow',
    39: 'Right Arrow',
    40: 'Down Arrow',
    44: 'Print Screen',
    45: 'Insert/Help',
    46: 'Delete',
    91: 'Left Windows key',
    92: 'Right Windows key',
    93: 'Select Key',
    112: 'F1',
    113: 'F2',
    114: 'F3',
    115: 'F4',
    116: 'F5',
    117: 'F6',
    118: 'F7',
    119: 'F8',
    120: 'F9',
    121: 'F10',
    122: 'F11',
    123: 'F12',
    144: 'Num Lock',
    145: 'Scroll Lock',
    224: 'Meta',
  };

  const updateRiscData = function () {
    _riscData.lastwall.end_timestamp = new Date().getTime();
    _riscData.lastwall.cadence_lengths = _inputLengths;
    if (!_autofillSet) {
      for (const i in _inputLengths) {
        if (_inputLengths[i]) {
          const length = _inputLengths[i];
          const inputField = document.getElementById(i);
          if (inputField) {
            if (inputField.value.length !== length) {
              // Assume if input field values do not match with tracked keystrokes
              // it was autofilled
              _autofillSet = true;
              _riscData.lastwall.autofill = true;
              break;
            }
          }
        }
      }
    }

    if (_riscField) {
      _riscField.value = JSON.stringify(_riscData);
    }
  };

  const addKeyEvent = function (e) {
    // console.log('addKeyEvent', e)
    const code = e.keyCode ? e.keyCode : e.which;
    // Code undefined means chrome autofill.
    // There was a document somewhere online that I cannot find anymore
    if (typeof code === 'undefined') {
      if (_riscData.cadence.control_keystroke.length > maxKeyEvents) {
        if (_debug) {
          console.log('Too control keystrokes, removing old keystrokes');
        }

        _riscData.cadence.control_keystroke.shift();
      }
      _riscData.cadence.control_keystroke.push({
        type: 'autofill',
        time: new Date().getTime() - _baseTimestamp,
      });
      _riscData.lastwall.autofill = true;
      _autofillSet = true;
    } else if (e.type === 'keydown' && _specialKeys[code]) {
      if (_riscData.cadence.control_keystroke.length > maxKeyEvents) {
        if (_debug) {
          console.log('Too control keystrokes, removing old keystrokes');
        }
        _riscData.cadence.control_keystroke.shift();
      }
      _riscData.cadence.control_keystroke.push({
        type: e.key,
        time: new Date().getTime() - _baseTimestamp,
      });
    } else if (!_specialKeys[code]) {
      if (_riscData.cadence.lastwall_key_event.length > maxKeyEvents) {
        if (_debug) {
          console.log('Too keystrokes, removing old keystrokes');
        }
        _riscData.cadence.lastwall_key_event.shift();
      }
      _riscData.cadence.lastwall_key_event.push({
        type: e.type,
        time: new Date().getTime() - _baseTimestamp,
      });
    }
    updateRiscData();
  };

  const cadenceKeyEvents = function (e) {
    // console.log('cadenceKeyEvents', e)
    const code = e.keyCode ? e.keyCode : e.which;
    // Code undefined means chrome autofill.
    // There was a document somewhere online that I cannot find anymore
    if (typeof code === 'undefined') {
      if (_riscData.cadence.control_keystroke.length > maxKeyEvents) {
        if (_debug) {
          console.log('Too control keystrokes, removing old keystrokes');
        }

        _riscData.cadence.control_keystroke.shift();
      }
      _riscData.cadence.control_keystroke.push({
        type: 'autofill',
        time: new Date().getTime() - _baseTimestamp,
      });
      _riscData.lastwall.autofill = true;
      _autofillSet = true;
    } else if (!_specialKeys[code]) {
      _riscData.cadence.key_event.push({
        type: e.type,
        time: new Date().getTime() - _baseTimestamp,
      });

      if (_inputLengths[e.target.name || e.target.id] === undefined) {
        _inputLengths[e.target.name || e.target.id] = 0;
      }

      if (e.type === 'keydown') {
        _inputLengths[e.target.name || e.target.id]++;
        const inputField = document.getElementById(e.target.id);
        if (inputField) {
          if (
            inputField.value.length !==
            _inputLengths[e.target.name || e.target.id]
          ) {
            // Key might have been held down
            _inputLengths[e.target.name || e.target.id] =
              inputField.value.length;
          }
        }
      }
    } else if (code === 46 || code === 8) {
      // If we are deleting a character, update the input fields.
      // how to tell if they are deleting multiple characters?
      // What if they select and delete multiple vs autofill?
      // What if they delete inner characters?
      // need to find a better way of getting cadence times
      if (_inputLengths[e.target.name || e.target.id] === undefined) {
        _inputLengths[e.target.name || e.target.id] = 0;
      }

      if (_inputLengths[e.target.name || e.target.id] > 0) {
        _inputLengths[e.target.name || e.target.id]--;
      }
      _riscData.cadence.key_event.pop();
    }
    updateRiscData();
  };

  const getFingerprintData = function () {
    if (typeof Fingerprint2 === 'undefined') {
      return;
    }
    const options = { excludes: { canvas: true, webgl: true } };
    // eslint-disable-next-line no-undef
    Fingerprint2.getPromise(options)
      .then((components) => {
        const parsedFingerprint = {};
        const values = components
          .filter(
            (component) =>
              ![
                'enumerateDevices',
                'pixelRatio',
                'doNotTrack',
                'audio',
                'fonts',
                'fontsFlash',
                'availableScreenResolution',
              ].includes(component.key),
          )
          .map((component) => component.value);
        // eslint-disable-next-line no-undef
        const murmur = Fingerprint2.x64hash128(values.join(''), 31);
        parsedFingerprint.id = murmur;
        for (const index in components) {
          if (components[index]) {
            parsedFingerprint[components[index].key] = components[index].value;
          }
        }
        _riscData.fingerprint = parsedFingerprint;
        updateRiscData();
      })
      .catch((err) => {
        _riscData.lastwall.exceptions.push(err.toString());
        updateRiscData();
      });
  };

  const detectFlash = function () {
    /* eslint-disable */
    var FlashDetect = new (function () {
      var self = this;
      self.installed = false;
      self.raw = '';
      self.major = -1;
      self.minor = -1;
      self.revision = -1;
      self.revisionStr = '';
      var activeXDetectRules = [
        {
          name: 'ShockwaveFlash.ShockwaveFlash.7',
          version: function (obj) {
            return getActiveXVersion(obj);
          },
        },
        {
          name: 'ShockwaveFlash.ShockwaveFlash.6',
          version: function (obj) {
            var version = '6,0,21';
            try {
              obj.AllowScriptAccess = 'always';
              version = getActiveXVersion(obj);
            } catch (err) {}
            return version;
          },
        },
        {
          name: 'ShockwaveFlash.ShockwaveFlash',
          version: function (obj) {
            return getActiveXVersion(obj);
          },
        },
      ];
      var getActiveXVersion = function (activeXObj) {
        var version = -1;
        try {
          version = activeXObj.GetVariable('$version');
        } catch (err) {}
        return version;
      };
      var getActiveXObject = function (name) {
        var obj = -1;
        try {
          obj = new ActiveXObject(name);
        } catch (err) {
          obj = { activeXError: true };
        }
        return obj;
      };
      var parseActiveXVersion = function (str) {
        var versionArray = str.split(',');
        return {
          raw: str,
          major: parseInt(versionArray[0].split(' ')[1], 10),
          minor: parseInt(versionArray[1], 10),
          revision: parseInt(versionArray[2], 10),
          revisionStr: versionArray[2],
        };
      };
      var parseStandardVersion = function (str) {
        var descParts = str.split(/ +/);
        var majorMinor = descParts[2].split(/\./);
        var revisionStr = descParts[3];
        return {
          raw: str,
          major: parseInt(majorMinor[0], 10),
          minor: parseInt(majorMinor[1], 10),
          revisionStr: revisionStr,
          revision: parseRevisionStrToInt(revisionStr),
        };
      };
      var parseRevisionStrToInt = function (str) {
        return parseInt(str.replace(/[a-zA-Z]/g, ''), 10) || self.revision;
      };
      self.majorAtLeast = function (version) {
        return self.major >= version;
      };
      self.minorAtLeast = function (version) {
        return self.minor >= version;
      };
      self.revisionAtLeast = function (version) {
        return self.revision >= version;
      };
      self.versionAtLeast = function (major) {
        var properties = [self.major, self.minor, self.revision];
        var len = Math.min(properties.length, arguments.length);
        for (i = 0; i < len; i++) {
          if (properties[i] >= arguments[i]) {
            if (i + 1 < len && properties[i] == arguments[i]) {
              continue;
            } else {
              return true;
            }
          } else {
            return false;
          }
        }
      };
      self.FlashDetect = (function () {
        if (navigator.plugins && navigator.plugins.length > 0) {
          var type = 'application/x-shockwave-flash';
          var mimeTypes = navigator.mimeTypes;
          if (
            mimeTypes &&
            mimeTypes[type] &&
            mimeTypes[type].enabledPlugin &&
            mimeTypes[type].enabledPlugin.description
          ) {
            var version = mimeTypes[type].enabledPlugin.description;
            var versionObj = parseStandardVersion(version);
            self.raw = versionObj.raw;
            self.major = versionObj.major;
            self.minor = versionObj.minor;
            self.revisionStr = versionObj.revisionStr;
            self.revision = versionObj.revision;
            self.installed = true;
          }
        } else if (
          navigator.appVersion.indexOf('Mac') == -1 &&
          window.execScript
        ) {
          var version = -1;
          for (var i = 0; i < activeXDetectRules.length && version == -1; i++) {
            var obj = getActiveXObject(activeXDetectRules[i].name);
            if (!obj.activeXError) {
              self.installed = true;
              version = activeXDetectRules[i].version(obj);
              if (version != -1) {
                var versionObj = parseActiveXVersion(version);
                self.raw = versionObj.raw;
                self.major = versionObj.major;
                self.minor = versionObj.minor;
                self.revision = versionObj.revision;
                self.revisionStr = versionObj.revisionStr;
              }
            }
          }
        }
      })();
    })();
    /* eslint-enable */
    if (FlashDetect.installed) {
      _riscData.generic.flash_version = `${FlashDetect.major}.${FlashDetect.minor}`;
    }
    updateRiscData();
  };

  const getDoNotTrack = function () {
    let doNotTrack = false;
    if (typeof navigator.doNotTrack !== 'undefined') {
      doNotTrack =
        navigator.doNotTrack === 'yes' ||
        navigator.doNotTrack === true ||
        navigator.doNotTrack === 1 ||
        navigator.doNotTrack === '1';
    } else if (typeof window.doNotTrack !== 'undefined') {
      doNotTrack =
        window.doNotTrack === 'yes' ||
        window.doNotTrack === true ||
        window.doNotTrack === 1 ||
        window.doNotTrack === '1';
    } else if (typeof navigator.msDoNotTrack !== 'undefined') {
      doNotTrack =
        navigator.msDoNotTrack === 'yes' ||
        navigator.msDoNotTrack === true ||
        navigator.msDoNotTrack === 1 ||
        navigator.msDoNotTrack === '1';
    }
    _riscData.generic.do_not_track = doNotTrack;
    updateRiscData();
  };

  const getActiveXSilverLight = function () {
    let slEnabled = false;
    if (navigator.appName === 'Internet Explorer') {
      try {
        // eslint-disable-next-line
        const slControl = new ActiveXObject('AgControl.AgControl');
        slEnabled = true;
      } catch (e) {
        slEnabled = false;
      }
    } else {
      try {
        if (navigator.plugins['Silverlight Plug-In']) {
          slEnabled = true;
        }
      } catch (e) {
        slEnabled = false;
      }
    }
    _riscData.lastwall.sl_enabled = slEnabled;
    updateRiscData();
  };

  const getIsPrivate = function () {
    const isIE10OrLater = function (userAgent) {
      const ua = userAgent.toLowerCase();
      if (ua.indexOf('msie') === 0 && ua.indexOf('trident') === 0) {
        return false;
      }
      const match = /(?:msie|rv:)\s?([\d.]+)/.exec(ua);
      if (match && parseInt(match[1], 10) >= 10) {
        return true;
      }
      return false;
    };

    if (window.webkitRequestFileSystem) {
      window.webkitRequestFileSystem(
        window.TEMPORARY,
        1,
        () => {
          _riscData.lastwall.is_private = false;
          updateRiscData();
        },
        () => {
          _riscData.lastwall.is_private = true;
          updateRiscData();
        },
      );
    } else if (window.indexedDB && /Firefox/.test(window.navigator.userAgent)) {
      try {
        const db = window.indexedDB.open('test');
        db.onerror = () => {
          _riscData.lastwall.is_private = true;
          updateRiscData();
        };

        db.onsuccess = () => {
          _riscData.lastwall.is_private = false;
          updateRiscData();
        };
      } catch (e) {
        _riscData.lastwall.is_private = true;
      }
    } else if (isIE10OrLater(window.navigator.userAgent)) {
      // Window should be defined here I'm not sure what could error.
      // Got this from the old code.
      try {
        _riscData.lastwall.is_private = !window.indexedDB;
      } catch (e) {
        _riscData.lastwall.is_private = true;
      }
    } else if (localStorage && /Safari/.test(window.navigator.userAgent)) {
      try {
        localStorage.setItem('test', 1);
        localStorage.removeItem('test');
        _riscData.lastwall.is_private = false;
      } catch (e) {
        _riscData.lastwall.is_private = true;
      }
    }
    updateRiscData();
  };

  const getWebRTCLocalIP = function () {
    function grepSDP(sdp) {
      let addr;
      let type;
      sdp.split('\r\n').forEach((line) => {
        // c.f. http://tools.ietf.org/html/rfc4566#page-39
        // eslint-disable-next-line no-bitwise
        if (~line.indexOf('a=candidate')) {
          // http://tools.ietf.org/html/rfc4566#section-5.13
          [, , , , addr, , , type] = line.split(' '); // http://tools.ietf.org/html/rfc5245#section-15.1
          if (type === 'host') {
            _riscData.lastwall.local_ip = addr;
            updateRiscData();
          }
          // eslint-disable-next-line no-bitwise
        } else if (~line.indexOf('c=')) {
          // http://tools.ietf.org/html/rfc4566#section-5.7
          [, , addr] = line.split(' ');
          _riscData.lastwall.local_ip = addr;
          updateRiscData();
        }
      });
    }

    try {
      const RTCPeerConnection =
        window.RTCPeerConnection ||
        window.webkitRTCPeerConnection ||
        window.mozRTCPeerConnection;
      if (RTCPeerConnection) {
        const rtc = new RTCPeerConnection({ iceServers: [] });
        if (1 || window.mozRTCPeerConnection) {
          rtc.createDataChannel('', { reliable: false });
        }

        rtc.onicecandidate = function (evt) {
          // convert the candidate to SDP so we can run it through our general parser
          // see https://twitter.com/lancestout/status/525796175425720320 for details
          if (evt.candidate) {
            grepSDP(`a=${evt.candidate.candidate}`);
          }
        };
        rtc.createOffer(
          (offerDesc) => {
            grepSDP(offerDesc.sdp);
            rtc.setLocalDescription(offerDesc);
          },
          (exception) => {
            _riscData.lastwall.exceptions.push(exception);
          },
        );
      } else {
        _riscData.lastwall.exceptions.push('No webRTC for local ip');
        // did not get local IP, do not set
      }
    } catch (err) {
      _riscData.lastwall.exceptions.push(err);
      // did not get local IP, do not set
    }
  };

  const getMediaDevices = function () {
    try {
      if (!navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices) {
        return;
      }

      navigator.mediaDevices
        .enumerateDevices()
        .then((devices) => {
          _riscData.generic.media_devices = devices;
          updateRiscData();
        })
        .catch((err) => {
          const msg = `Media devices error - ${err}`;
          _riscData.lastwall.exceptions.push(msg);
        });
    } catch (ex) {
      const msg = `Media devices error - ${ex}`;
      _riscData.lastwall.exceptions.push(msg);
    }
  };

  const getBrowserData = function () {
    _riscData.generic.referrer = document.referrer;

    _riscData.generic.user_agent = navigator.userAgent;
    _riscData.generic.app_name = navigator.appName;
    _riscData.generic.app_version = navigator.appVersion;
    _riscData.generic.platform = navigator.platform;

    _riscData.generic.window_width = window.innerWidth || 0;
    _riscData.generic.window_height = window.innerHeight || 0;

    _riscData.generic.java_enabled = false;
    if (navigator.javaEnabled) {
      _riscData.generic.java_enabled = navigator.javaEnabled();
    }

    _riscData.generic.cookie_enabled = navigator.cookieEnabled;
    _riscData.generic.storage_enabled = !!localStorage;

    getMediaDevices();
    getDoNotTrack();
    getActiveXSilverLight();
    detectFlash();
    getIsPrivate();
    getWebRTCLocalIP();
    updateRiscData();
  };

  const loadedFunction = function (
    inputElements,
    _serviceName,
    previousRiscInfo,
    riscField,
    // lastwallIframe,
  ) {
    getFingerprintData();
    getBrowserData();
    window.setInterval(updateRiscData, 1000);
    inputFieldsWatched = inputElements;

    if (attachEventListener != null) {
      // catch character codes
      document.addEventListener('keyup', addKeyEvent);
      document.addEventListener('keydown', addKeyEvent);

      Object.values(inputElements).forEach((element) => {
        if (element) {
          self.addInputElement(element);
        }
      });
    }

    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    _riscData.lastwall.is_mobile =
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
        userAgent,
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(
        userAgent.substr(0, 4),
      );

    if (riscField) {
      let riscInput = riscField;
      if (typeof riscField === 'string') {
        riscInput = document.getElementById(riscField);
      }
      _riscField = riscInput;
    }

    if (previousRiscInfo) {
      _riscData = previousRiscInfo;
    }

    // if (lastwallIframe) {
    //   _lastwallIframe = lastwallIframe;
    // }
    updateRiscData();

    // Clipboard
    // Clipboard events do not trigger events. Couldn't get add event listener to work..
    if (_allowClipboard) {
      // Not sure what we'd want to do with the clipboard just yet.
      // Especially since the events didn't work.
      // navigator.clipboard.readText().then(() => {
      //  Example
      // }).catch(() => {
      //  Example
      // })
    }
  };

  self.initialize = function ({
    inputElements,
    serviceName,
    previousRiscInfo,
    riscField,
    lastwallIframe,
  }) {
    if (
      document.readyState === 'complete' ||
      document.readyState === 'interactive'
    ) {
      loadedFunction(
        inputElements,
        serviceName,
        previousRiscInfo,
        riscField,
        lastwallIframe,
      );
    } else {
      const windowEventListener = window[`${attachEventListener}`];
      windowEventListener('load', () => {
        loadedFunction(
          inputElements,
          serviceName,
          previousRiscInfo,
          riscField,
          lastwallIframe,
        );
      });
    }
  };

  self.addInputElement = function (elementId) {
    if (typeof elementId === 'string') {
      const element = document.getElementById(elementId);

      // const elementListener = element[`${attachEventListener}`];
      element.addEventListener('keyup', cadenceKeyEvents);
      element.addEventListener('keydown', cadenceKeyEvents);
    }
  };

  self.removeInputElement = function (element) {
    element.removeEventListener('keyup', cadenceKeyEvents);
    element.removeEventListener('keydown', cadenceKeyEvents);
  };

  self.stopRisc = function (clearCadence) {
    document.removeEventListener('keyup', addKeyEvent);
    document.removeEventListener('keydown', addKeyEvent);

    Object.values(inputFieldsWatched).forEach((inputField) => {
      if (inputField) {
        let element = inputField;
        if (typeof element === 'string') {
          element = document.getElementById(element);
        }
        self.removeInputElement(inputField);
      }
    });

    if (clearCadence) {
      self.clearCadenceData();
    }
  };

  self.clearCadenceData = () => {
    // Clears all cadence data for resubmission. This is because we submit the risc data multiple
    // times per login.
    _riscData.cadence.key_event = [];
    _riscData.cadence.mouse_event = [];
    _riscData.cadence.lastwall_key_event = [];
    _riscData.cadence.frontend_cleared = true;
  };

  self.setAutofill = function (autofillValue) {
    _riscData.lastwall.autofill = autofillValue;
    _autofillSet = autofillValue;
  };

  self.setDebug = function (debug) {
    _debug = debug;
  };

  self.getRiscData = async function () {
    _riscData.lastwall.end_timestamp = new Date().getTime();
    return _riscData;
  };
};

export default RiscDataCollector;
