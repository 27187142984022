import React from 'react';

import Typography from '@mui/material/Typography';
import { Card } from '@mui/material';

const NotFound404Page = () => (
  <Card style={{ padding: 16 }}>
    <Typography>404 Page Not Found</Typography>
  </Card>
);

export default NotFound404Page;
