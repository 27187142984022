/* eslint-disable import/prefer-default-export */

/**
 * Generate a subdomain for the PIV service.
 *
 * This serves the double purpose of making a "random" PIV server url and
 * provides some context to the PIV SNI callback to determine which PIV
 * certificates to fetch.
 *
 * Note: Because the `authSessionSlug` goes into a URL it becomes lowercase.
 * This affects the database query.
 *
 * @param authSessionSlug The slug identifier of the current auth Session
 * @returns A subdomain for the PIV service.
 */
export const generatePivSubdomain = (authSessionSlug: string): string => {
  const suffix = Math.floor(Math.random() * 1_000);
  return `${authSessionSlug}-${suffix}`;
};
