import React from 'react';

import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';

import ReactMarkdown from 'react-markdown';

import CardWithLogo from '../../components/card/CardWithLogo';

const TermsAndConditionsView = ({
  logoURL,
  acceptTerms = () => {},
  termsAndConditionsMarkdown,
  termsAndConditionsAcceptButtonText,
}) => (
  <CardWithLogo logoURL={logoURL}>
    <Box
      sx={{
        ml: 5,
        mr: 5,
        height: '30vh',
        overflowY: 'scroll',
      }}
    >
      <ReactMarkdown
        renderers={{
          paragraph: Typography,
        }}
      >
        {termsAndConditionsMarkdown}
      </ReactMarkdown>
    </Box>
    <Button
      sx={{
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: 4,
        marginBottom: 5,
      }}
      color="primary"
      variant="contained"
      onClick={acceptTerms}
    >
      {termsAndConditionsAcceptButtonText}
    </Button>
  </CardWithLogo>
);

export default TermsAndConditionsView;
