import React from 'react';

import Typography from '@mui/material/Typography';
// import Button from '@mui/material/Button'
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
// import ListSubheader from '@mui/material/ListSubheader'

import CardWithLogo from '../../components/card/CardWithLogo';

const UserSelectionView = ({
  error = null,
  isPending = false,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  consumeToken = (_tokenID) => {},
  factorAuthorizationTokens,
  logoURL,
}) => {
  // Check if browser is any of the browsers that do not support TextField
  // adornments.
  const ua = navigator.userAgent;
  const isPanGlobalProtect = ua.toLowerCase().includes('globalprotect');
  const isSalesforce = ua.toLowerCase().includes('salesforce');
  const hideAdornments = isPanGlobalProtect || isSalesforce;

  if (error) {
    return <Typography color="error">{error}</Typography>;
  }

  return (
    <div>
      {/* <Card className={classes.card}> */}
      <CardWithLogo logoURL={logoURL}>
        <Typography variant="h2" sx={{ pl: 2, pr: 2, mb: 1 }}>
          Select Account
        </Typography>
        <List
          aria-labelledby="two-factor-setup-subheader"
          sx={{ pb: 4 }}
          // @ts-ignore
          // subheader={(
          //   <ListSubheader component="div" id="two-factor-setup-subheader">
          //     Select Account
          //   </ListSubheader>
          // )}
        >
          {factorAuthorizationTokens.map(({ id, username }) => (
            <ListItem
              button
              key={id}
              onClick={() => consumeToken(id)}
              disabled={isPending}
            >
              {!hideAdornments && (
                <ListItemIcon>
                  <i className="material-icons">account_circle</i>
                </ListItemIcon>
              )}
              <ListItemText primary={<>{username}</>} />
            </ListItem>
          ))}
        </List>
      </CardWithLogo>
    </div>
  );
};

export default UserSelectionView;
