import React, { useState, useEffect } from 'react';

import { useHistory } from 'react-router-dom';
import { useStateMachine } from 'little-state-machine';
import { useSnackbar } from 'notistack';

import TOTPSetupView from './TOTPSetup.view';
import { useFetchGraphQL } from '../../core/hooks/apiFetchOptions';

const TOTPSetupPage = () => {
  const snackbar = useSnackbar();
  const history = useHistory();
  const { state } = useStateMachine() as any;

  const initialStepperState = {
    activeStep: 0,
  };
  const [{ activeStep }, setStepperState] = useState(initialStepperState);

  const createTOTPSecretMutation = `
    mutation {
      createTOTPSecret {
        qrCodeDataURL
        base32
      }
    }
  `;

  const createTOTPSecret: any = useFetchGraphQL(
    ({ fetchGraphQL }) =>
      fetchGraphQL({
        state,
        query: createTOTPSecretMutation,
      }),
    [],
  );

  useEffect(createTOTPSecret.run, []);

  const verifyTOTPCodeMutation = `
    mutation($input: VerifyTOTPCodeInput!) {
      verifyTOTPCode(input: $input) {
        id
      }
    }
  `;

  const verifyTOTPCode = useFetchGraphQL(
    async ({ args, fetchGraphQL }) => {
      const [
        {
          values: { code },
        },
      ] = args;

      setStepperState({
        activeStep: 2,
      });

      await fetchGraphQL({
        state,
        query: verifyTOTPCodeMutation,
        variables: {
          input: {
            code,
          },
        },
      });

      snackbar.enqueueSnackbar('TOTP registration successful!');
      history.push('/two-factor-setup');
    },
    [state],
  );

  useEffect(() => {
    if (verifyTOTPCode.error != null) {
      setStepperState({
        activeStep: 1,
      });
    }
  }, [verifyTOTPCode.error]);

  const onSubmit = async (values) => {
    if (activeStep === 0) {
      setStepperState({
        activeStep: 1,
      });
    } else if (activeStep === 1) {
      await verifyTOTPCode.run(values);
    } else {
      throw new Error('Invalid stepper activeStep');
    }
  };

  if (createTOTPSecret.isPending) {
    // https://github.com/async-library/react-async/issues/242
    return <div />;
  }

  const { qrCodeDataURL, base32 } =
    createTOTPSecret.data?.createTOTPSecret || {};

  return (
    <TOTPSetupView
      {...{
        createTOTPCodeError: createTOTPSecret.error?.message,
        verifyTOTPCodeError: verifyTOTPCode.error?.message,
        isSubmitting: verifyTOTPCode.isPending,
        activeStep,
        onSubmit,
        onBackClick: () => setStepperState(initialStepperState),
        qrCodeImage: qrCodeDataURL,
        otpAuthURL: `otpauth://totp/Lastwall:%20${state.login.username}?secret=${base32}`,
        base32,
      }}
    />
  );
};

export default TOTPSetupPage;
