import * as React from 'react';
import * as useAsync from '@lastwall/use-async';

export const apiPostOptions = (state): RequestInit => {
  if (state == null) {
    throw new Error('State cannot be null');
  }

  const { csrfToken } = state.orgProfile || {};
  // console.log({ state, csrfToken })

  const headers: any = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };

  if (csrfToken) {
    headers.Authorization = `Bearer ${csrfToken}`;
  }

  return {
    method: 'POST',
    mode: 'cors',
    credentials: 'include',
    headers,
  };
};

export const graphqlURL = () => {
  let ssoServer = `https://${window.location.hostname}`;

  // @ts-ignore
  if (!window.isProduction) {
    ssoServer = `http://${window.location.hostname}:4001`;
  }

  return `${ssoServer}/graphql`;
};

export const initConfig = () => {
  Object.assign(useAsync.config, {
    React,
    graphqlURL: () => graphqlURL(),
  });
};

export const fetchGraphQL = async ({
  query,
  variables = null,
  state = null,
  signal,
}) =>
  useAsync.fetchGraphQL({
    query,
    variables,
    state,
    options: apiPostOptions(state),
    signal,
  });

export const useFetchGraphQL = (cb, inputs) => {
  const asyncHook = useAsync.useAsync({
    // eslint-disable-next-line @typescript-eslint/require-await
    deferFn: React.useCallback(async (args, { signal }) => {
      try {
        return cb({
          fetchGraphQL: (opts) =>
            fetchGraphQL({
              signal,
              ...opts,
            }),
          signal,
          args,
        });
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
        throw error;
      }
    }, inputs),
  });

  return asyncHook;
};
