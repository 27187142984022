import React, { useContext, useEffect } from 'react';
import { useStateMachine } from 'little-state-machine';
import { useAsync } from '@lastwall/use-async';

import TwoFactorPIVView from './TwoFactorPIV.view';
import useNavigateToNext from '../../core/hooks/useNavigateToNext';
import findFactor from '../../core/store/stage/findFactor';
import { useFetchGraphQL } from '../../core/hooks/apiFetchOptions';
import stageQueryFragment from '../../core/store/stage/stageQuery.fragment';
import RISCContext from '../../core/providers/risc/RISCContext';
import { generatePivSubdomain } from '../../core/piv/utils';

const TwoFactorPivPage = () => {
  const navigateToNext = useNavigateToNext();
  const { getRiscData } = useContext(RISCContext);
  const { state } = useStateMachine() as any;
  const { factors } = state.stage;
  const factor = findFactor(state, 'PIV');

  const consumeFATokenMutation = `
    mutation($input: ConsumeFactorAuthorizationTokenInput!) {
      consumeFactorAuthorizationToken(input: $input) {
        ${stageQueryFragment}
      }
    }
  `;

  const consumeFAToken: any = useFetchGraphQL(
    async ({ fetchGraphQL, args }) => {
      const riscData = await getRiscData();

      return fetchGraphQL({
        state,
        query: consumeFATokenMutation,
        variables: {
          input: {
            tokenID: args[0],
            riscData: JSON.stringify(riscData),
          },
        },
      });
    },
    [state],
  );

  const fetchPIV = useAsync({
    deferFn: async (_args, { signal }) => {
      const { pivDomain, csrfToken, authSessionSlug } = state.orgProfile;

      const subdomain = generatePivSubdomain(authSessionSlug);

      let domainAndProtocol = `https://${subdomain}.${pivDomain}`;
      if (process.env.NODE_ENV === 'development') {
        domainAndProtocol = `http://${pivDomain}`;
      }

      const url =
        `${domainAndProtocol}/api/v2/piv-check?` +
        `csrfToken=${encodeURIComponent(csrfToken)}` +
        `&factorID=${encodeURIComponent(factor.id)}`;

      const res = await fetch(url, {
        method: 'POST',
        mode: 'cors',
        credentials: 'include',
        // headers: {
        //   authorization: `Bearer ${csrfToken}`,
        //   // 'Content-Type': 'application/json',
        // },
        signal,
        // body: JSON.stringify({
        //   factorID: factor.id,
        // }),
      });

      let json;
      try {
        // eslint-disable-next-line prefer-const
        json = await res.json();
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
        throw new Error('An error occurred. Please try again');
      }

      if (!res.ok || json.errors?.length) {
        throw new Error(json.errors[0].message);
      }

      const pivResp = json.data.createPIVAuthorizationTokens;
      const tokens = pivResp.stage.factorAuthorizationTokens;

      if (tokens.length === 1) {
        const consumeRes = await consumeFAToken.run(tokens[0].id);
        // console.log({ consumeRes })

        navigateToNext(consumeRes.consumeFactorAuthorizationToken);
      } else {
        navigateToNext(pivResp);
      }
    },
  });

  useEffect(() => {
    if (fetchPIV.error != null) {
      // eslint-disable-next-line no-console
      console.error(fetchPIV.error);
    }
  }, [fetchPIV.error]);

  return (
    <TwoFactorPIVView
      {...{
        logoURL: state.orgProfile.logoURL,
        isPending: fetchPIV.isPending,
        error: (fetchPIV.error || consumeFAToken.error)?.message,
        factors,
        onSubmit: fetchPIV.run,
      }}
    />
  );
};

export default TwoFactorPivPage;
