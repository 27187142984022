import React, { useEffect } from 'react';

// Support
import { Route, Switch, useHistory, useLocation } from 'react-router-dom';
import { useStateMachine } from 'little-state-machine';

// Material
import Typography from '@mui/material/Typography';

// Custom
import SuccessPage from '../success/Success.page';
import NotFound404Page from '../notFound/NotFound404.page';
import LogoutPage from '../../logout/Logout.page';
import KillSwitchPage from '../../kill-switch/KillSwitch.page';
import InactivePage from '../inactive/Inactive.page';
import GetOrgPage from '../../get-org/GetOrg.page';

// Custom login flow
import TermsAndConditionsPage from '../../login-flow/terms-and-conditions/TermsAndConditions.page';
import FrictionPage from '../../login-flow/friction/Friction.page';
import Password from '../../login-flow/password/Password';
import TwoFactorSetupPage from '../../onboarding/two-factor-setup/TwoFactorSetup.page';
import TwoFactorRecoveryCodePage from '../../login-flow/two-factor-recovery-code/TwoFactorRecoveryCode.page';
import TwoFactorPIVPage from '../../login-flow/two-factor-piv/TwoFactorPIV.page';
import TwoFactorSMSPage from '../../login-flow/two-factor-sms/TwoFactorSMS.page';
import TwoFactorTOTPPage from '../../login-flow/two-factor-totp/TwoFactorTOTP.page';
import TwoFactorWebAuthnPage from '../../login-flow/two-factor-webauthn/TwoFactorWebAuthn.page';
import PasswordResetPage from '../../login-flow/password-reset/PasswordReset.page';
import UserSelectionPage from '../../login-flow/user-selection/UserSelection.page';

// Custom Onboarding
import SMSSetupPage from '../../onboarding/sms-setup/SMSSetup.page';
import PIVSetupPage from '../../onboarding/piv-setup/PIVSetup.page';
import TOTPSetupPage from '../../onboarding/totp-setup/TOTPSetup.page';
import WebauthnSetupPage from '../../onboarding/webauthn-setup/WebauthnSetup.page';
import RecoveryCodeSetupPage from '../../onboarding/recovery-code-setup/RecoveryCodeSetup.page';

// API
import { useFetchGraphQL } from '../hooks/apiFetchOptions';

// Stores
import {
  updateOrgProfile,
  orgProfileQuery,
  getLogoURL,
} from '../store/orgProfileStore';
import { updateLogin } from '../store/loginStore';
import { getLoggedInOrgProfile } from '../store/loggedInOrgProfileStore';

// Providers
import ProviderStack from '../providers/ProviderStack';
import CardWithLogo from '../../components/card/CardWithLogo';
import PasswordResetNotAllowed from '../passwordResetNotAllowed/PasswordResetNotAllowed';
import IalSetupPage from '../../ial-setup/IalSetup.page';

const Routes = () => {
  // Skip all the org profile stuff on logout
  const location = useLocation();
  const history = useHistory();
  const scope = '/:httpsOption?/:lastwallUrl?';

  if (location.pathname === '/logout') {
    return (
      <Switch>
        <Route path="/logout" exact>
          <LogoutPage />
        </Route>
      </Switch>
    );
  }

  const { actions, state } = useStateMachine({
    updateOrgProfile,
    updateLogin,
  }) as any;

  const { orgProfile } = state;

  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const { a: authSessionSlug } = Object.fromEntries(query.entries());

  useEffect(() => {
    if (authSessionSlug) {
      actions.updateLogin({ authSessionSlug });
    }
  }, [authSessionSlug]);

  // Org profiles are only loaded on the first page load - see the useEffect() after this
  // useFetchGraphQL().
  //
  // The routes on this page wait to render until the orgProfile is loaded.
  const orgProfileFetch: any = useFetchGraphQL(async ({ fetchGraphQL }) => {
    const data = await fetchGraphQL({
      query: orgProfileQuery,
      variables: {
        input: {
          authSessionSlug,
        },
      },
      state: {
        orgProfile: {
          csrfToken: null,
        },
      },
    });

    actions.updateOrgProfile(data);

    // skip authentication if already logged in to this profile
    const loggedInOrgProfile = getLoggedInOrgProfile();
    const successURL = '/success';

    if (
      loggedInOrgProfile?.policyID === data.orgProfile.policyID &&
      history.location.pathname !== successURL
    ) {
      history.replace(successURL);
    }
  }, []);

  useEffect(() => {
    if (!orgProfile.loaded) {
      orgProfileFetch.run();
    }
  }, [orgProfile.loaded, orgProfileFetch.run]);

  useEffect(() => {
    if (orgProfileFetch.error) {
      // eslint-disable-next-line no-console
      console.error(orgProfileFetch.error);
    }
  }, [orgProfileFetch]);

  if (orgProfileFetch.error) {
    return (
      <Typography color="error">{orgProfileFetch.error.message}</Typography>
    );
  }

  if (!orgProfile.loaded) {
    return <div />;
  }

  const logoURL = getLogoURL(orgProfile);
  return (
    <Switch>
      <Route path={`${scope}/terms`} exact>
        <TermsAndConditionsPage />
      </Route>

      {/* Primary Factors */}
      <Route
        path={[`${scope}/password`, `${scope}/webauthn`]}
        render={() => (
          <CardWithLogo logoURL={logoURL}>
            <Route path={`${scope}/password`} exact>
              <Password />
            </Route>

            <Route path={`${scope}/webauthn`} exact>
              <TwoFactorWebAuthnPage />
            </Route>
          </CardWithLogo>
        )}
      />

      {/* Secondary Factors */}

      <Route path={`${scope}/recovery-code`} exact>
        <TwoFactorRecoveryCodePage />
      </Route>

      <Route path={`${scope}/piv`} exact>
        <TwoFactorPIVPage />
      </Route>

      <Route path={`${scope}/sms`} exact>
        <TwoFactorSMSPage />
      </Route>

      <Route path={`${scope}/totp`} exact>
        <TwoFactorTOTPPage />
      </Route>

      <Route path={`${scope}/user-selection`} exact>
        <UserSelectionPage />
      </Route>

      {/* Setup */}

      <Route path={`${scope}/two-factor-setup`} exact>
        <TwoFactorSetupPage />
      </Route>

      <Route path={`${scope}/setup-sms`} exact>
        <SMSSetupPage />
      </Route>

      <Route path={`${scope}/setup-totp`} exact>
        <TOTPSetupPage />
      </Route>

      <Route path={`${scope}/setup-piv`} exact>
        <PIVSetupPage />
      </Route>

      <Route path={`${scope}/setup-webauthn`} exact>
        <WebauthnSetupPage />
      </Route>

      <Route path={`${scope}/setup-recovery-code`} exact>
        <RecoveryCodeSetupPage />
      </Route>

      {/* Etc */}

      <Route path="/friction">
        <FrictionPage />
      </Route>

      <Route path={`${scope}/password-reset`} exact>
        <PasswordResetPage />
      </Route>

      <Route path={`${scope}/logout`} exact>
        <LogoutPage />
      </Route>

      <Route path={`${scope}/success`} exact>
        <SuccessPage />
      </Route>

      <Route path={`${scope}/ial-setup`} exact>
        <IalSetupPage />
      </Route>

      <Route path="*">
        <NotFound404Page />
      </Route>
    </Switch>
  );
};

export const nonAuthSessionRoutes = () => [
  <Route path="/get-org" key="getOrg" exact>
    <ProviderStack>
      <GetOrgPage />
    </ProviderStack>
  </Route>,
  <Route path="/kill/:authSessionSlug/:killSwitchToken" key="killSwitch" exact>
    <ProviderStack>
      <KillSwitchPage />
    </ProviderStack>
  </Route>,
  <Route path="/:serviceID/password-reset-not-allowed" key="no-reset" exact>
    <ProviderStack>
      <PasswordResetNotAllowed />
    </ProviderStack>
  </Route>,
  <Route path="/inactive" key="inactive-service" exact>
    <ProviderStack>
      <InactivePage />
    </ProviderStack>
  </Route>,
];

export default Routes;
