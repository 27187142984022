const routeSuffixes = {
  PASSWORD: 'password',
  TOTP: 'totp',
  SMS: 'sms',
  PIV: 'piv',
  RECOVERY_CODE: 'recovery-code',
  WEBAUTHN: 'webauthn',
};

const PRIMARY_FACTORS_REQUIRING_USERNAME = ['PASSWORD', 'WEBAUTHN'];

const factorURL = (factorType, { state }) => {
  const { userLoginNameEntered } = state.stage || {};

  const route = `/${routeSuffixes[factorType]}`;

  sessionStorage.setItem(
    'factorType',
    JSON.stringify(routeSuffixes[factorType]),
  );

  if (
    PRIMARY_FACTORS_REQUIRING_USERNAME.includes(factorType) &&
    !userLoginNameEntered
  ) {
    return `${route}`;
  }

  return route;
};

export default factorURL;
