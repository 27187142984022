import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';

import CardWithLogo from '../components/card/CardWithLogo';

export interface FormValues {
  username: string;
}
interface GetOrgViewProps {
  onSubmit: (values: FormValues) => Promise<unknown>;
  logoURL: string;
  serverError: string;
}

const initialFormValues: FormValues = {
  username: '',
};

export const GetOrgView = ({
  onSubmit,
  logoURL,
  serverError,
}: GetOrgViewProps) => {
  const { handleSubmit, control, formState, errors } = useForm({
    defaultValues: initialFormValues,
  });

  return (
    <CardWithLogo logoURL={logoURL}>
      <Box sx={{ ml: 5, mr: 5 }}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Controller
            name="username"
            control={control}
            rules={{ required: true }}
            render={({ onChange, value }) => (
              <TextField
                onChange={onChange}
                value={value}
                label="User Name"
                sx={{
                  mt: 4,
                  width: '100%',
                }}
                error={!!errors.username || !!serverError}
                helperText={errors?.username?.message || serverError}
                InputProps={{
                  sx: {
                    WebkitUserSelect: 'text',
                    userSelect: 'text',
                  },
                }}
              />
            )}
          />
          <Button
            sx={{
              display: 'flex',
              flex: 0,
              justifyContent: 'center',
              width: '100%',
              mt: 4,
              mb: 5,
            }}
            color="primary"
            type="submit"
            variant="contained"
            disabled={formState.isSubmitting}
          >
            Next
          </Button>
        </form>
      </Box>
    </CardWithLogo>
  );
};
