import React, { useEffect, useLayoutEffect, useRef } from 'react';

import { useForm } from 'react-hook-form';

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Icon from '@mui/material/Icon';
import TextField from '@mui/material/TextField';

import TwoFactorSelector from '../two-factor-selector/TwoFactorSelector';

const TwoFactorRecoveryCodeView = ({
  factors,
  onSubmit = () => {},
  isSubmitting = false,
  error: externalError = null,
}) => {
  const { handleSubmit, register, errors, setError, formState } = useForm();

  useEffect(() => {
    if (!isSubmitting && externalError) {
      setError('code', {
        type: 'server',
        message: externalError,
        shouldFocus: true,
      });
    }
  }, [externalError, isSubmitting]);

  const serialRef: any = useRef();
  useLayoutEffect(() => serialRef.current.focus(), []);

  // if (failed) {
  //   return (
  //     <Typography variant="body1" paragraph>
  //       Failed 2FA too many times. Please restart and try again.
  //     </Typography>
  //   )
  // }

  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.only('xs'));
  const textFieldMargin = mobile ? 'dense' : 'normal';
  const textFieldSize = mobile ? 'small' : 'medium';
  const buttonSize = textFieldSize;

  return (
    <form
      // @ts-ignore
      onSubmit={handleSubmit((values) => onSubmit({ values, setError }))}
    >
      <Card
        sx={{
          pl: 5,
          pr: 5,
          pt: 3,
          pb: 3,
          width: 400,
          xs: {
            // 360px is the smallest viewport width of popular Android phones atm
            width: 360,
            paddingLeft: 3,
            paddingRight: 3,
            paddingTop: 1,
            paddingBottom: 2,
          },
          textAlign: 'center',
        }}
      >
        <Typography variant="body1" paragraph={!mobile} gutterBottom={mobile}>
          <Icon fontSize="inherit">phonelink_lock</Icon>
          Enter your recovery code below
        </Typography>

        <TextField
          name="serial"
          label="Serial Number"
          placeholder="eg. 8"
          variant="outlined"
          autoComplete="off"
          margin={textFieldMargin}
          size={textFieldSize}
          autoFocus
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          inputRef={(e) => {
            serialRef.current = e;
            register(e, {
              required: 'Required',
              pattern: {
                value: /[0-9]+/,
                message: 'Serial Number must be a number',
              },
            });
          }}
          error={errors.serial != null}
          helperText={errors.serial?.message}
        />

        <TextField
          name="code"
          label="Recovery Code"
          placeholder="1A2B-3C4B-5D6E-7F8G"
          variant="outlined"
          autoComplete="off"
          margin={textFieldMargin}
          size={textFieldSize}
          autoFocus
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          inputRef={register({
            required: 'Required',
            minLength: {
              value: 6,
              message: 'Not a valid recovery code',
            },
          })}
          error={errors.code != null}
          helperText={errors.code?.message}
        />

        <Button
          sx={{
            mr: 2,
            xs: { mt: 1 },
          }}
          color="primary"
          type="submit"
          size={buttonSize}
          variant="contained"
          disabled={isSubmitting || formState.isSubmitting}
        >
          Authenticate
        </Button>
        <TwoFactorSelector
          factors={factors.filter((x) => x.type !== 'RECOVERY_CODE')}
        />
      </Card>
    </form>
  );
};

export default TwoFactorRecoveryCodeView;
