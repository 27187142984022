import React, { useState } from 'react';

import { useStateMachine } from 'little-state-machine';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router-dom';

import * as Webauthn from '@lastwall/webauthn-client';

import { useFetchGraphQL } from '../../core/hooks/apiFetchOptions';
import TwoFactorWebAuthnView from './WebauthnSetup.view';

const WebauthnSetupPage = () => {
  const history = useHistory();
  const snackbar = useSnackbar();
  const { state } = useStateMachine() as any;

  const [webAuthnState, setWebAuthnState] = useState('getUserConsent');

  const createChallengeMutation = `
    mutation {
      createWebAuthnRegistrationChallenge {
        id
        creationOptions
      }
    }
  `;

  const registerMutation = `
    mutation($input: RegisterWebAuthnInput!) {
      registerWebAuthnCredential(input: $input) {
        id
      }
    }
  `;

  let challengeID;
  let attestation = {};

  const getCredentialOptions: any = useFetchGraphQL(
    async ({ fetchGraphQL }) => {
      setWebAuthnState('getAttestation');
      try {
        const data = await fetchGraphQL({
          state,
          query: createChallengeMutation,
        });

        const { creationOptions, id } =
          data.createWebAuthnRegistrationChallenge;
        challengeID = id;

        attestation = await Webauthn.create(creationOptions);
        setWebAuthnState('getNickname');
      } catch (e) {
        setWebAuthnState('getUserConsent');
      }
    },
    [state],
  );

  const finished: any = useFetchGraphQL(
    async ({ args, fetchGraphQL }) => {
      setWebAuthnState('submitResults');
      try {
        const { nickname } = args[0].values;

        await fetchGraphQL({
          state,
          query: registerMutation,
          variables: {
            input: {
              challengeID,
              attestation,
              nickname,
            },
          },
        });

        snackbar.enqueueSnackbar('WebAuthn registration successful!');
        history.push('/two-factor-setup');
      } catch (e) {
        setWebAuthnState('getUserNickname');
      }
    },
    [state],
  );

  return (
    <TwoFactorWebAuthnView
      {...{
        logoURL: state.orgProfile.logoURL,
        error: getCredentialOptions.error?.message,
        webAuthnState,
        onGetCredentialOptions: getCredentialOptions.run,
        onFinished: finished.run,
        onFinishError: finished.error?.message,
      }}
    />
  );
};

export default WebauthnSetupPage;
