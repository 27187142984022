import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';

import { useForm } from 'react-hook-form';
import FadeIn from 'react-fade-in';

import { useTheme, SxProps, Theme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Box from '@mui/material/Box';

import { loginAndPasswordCommonStyles } from '../../components/styles/Login.styles';

const styles = {
  ...loginAndPasswordCommonStyles,
  card: {
    paddingLeft: 5,
    paddingRight: 5,
    paddingTop: 3,
    paddingBottom: 3,
    width: 400,
    xs: {
      // 360px is the smallest viewport width of popular Android phones atm
      width: 360,
      paddingLeft: 2,
      paddingRight: 2,
      paddingTop: 1,
      paddingBottom: 2,
    },
    textAlign: 'center',
  },
  hidePassword: {
    cursor: 'pointer',
  },
  passwordAutocompleteTrap: {
    display: 'none',
  },
};

const PasswordReset = ({
  onSubmit,
  isSubmitting = false,
  defaultValues,
  error: externalError = null,
}) => {
  // Check if browser is any of the browsers that do not support TextField
  // adornments.
  const ua = navigator.userAgent;
  const isPanGlobalProtect = ua.toLowerCase().includes('globalprotect');
  const isSalesforce = ua.toLowerCase().includes('salesforce');
  const hideAdornments = isPanGlobalProtect || isSalesforce;

  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.only('xs'));
  const textFieldMargin = mobile ? 'dense' : 'normal';
  const textFieldSize = mobile ? 'small' : 'medium';
  const buttonSize = textFieldSize;

  const [hidePasssword, setHidePasssword] = useState(true);

  const { handleSubmit, register, errors, setError, formState, getValues } =
    useForm({ defaultValues });

  const error = errors.password?.message;

  useEffect(() => {
    if (!isSubmitting) {
      setError('password', {
        type: 'server',
        message: externalError,
        shouldFocus: true,
      });
    }
  }, [externalError, isSubmitting]);

  const passwordResetRef: any = useRef();
  useLayoutEffect(() => passwordResetRef.current.focus(), []);

  return (
    <Box sx={styles.formContainer}>
      <form
        autoComplete="off"
        // @ts-ignore
        onSubmit={handleSubmit((values) => onSubmit({ values, setError }))}
      >
        <input
          style={styles.passwordAutocompleteTrap}
          id="_autocompleteTrap"
          type="password"
        />

        <Card sx={styles.card as SxProps<Theme>}>
          <FadeIn delay={100} transitionDuration={500}>
            <Typography variant="h1" sx={styles.title}>
              Password Reset Required
            </Typography>
            <TextField
              name="password"
              label="Enter your new password"
              variant="outlined"
              type={hidePasssword ? 'password' : 'text'}
              autoComplete="off"
              autoFocus
              fullWidth
              margin={textFieldMargin}
              size={textFieldSize}
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                endAdornment: hideAdornments ? undefined : (
                  <InputAdornment position="end">
                    <i
                      style={{ cursor: 'pointer' }}
                      className="material-icons"
                      onClick={() => setHidePasssword(!hidePasssword)}
                      onKeyPress={(e) =>
                        e.keyCode === 13 && setHidePasssword(!hidePasssword)
                      }
                      role="button"
                      tabIndex={-1}
                    >
                      {hidePasssword ? 'visibility' : 'visibility_off'}
                    </i>
                  </InputAdornment>
                ),
              }}
              inputRef={(e) => {
                passwordResetRef.current = e;
                register(e, {
                  required: 'Required',
                });
              }}
              error={error != null}
              helperText={error?.split('\n').map((text) => <p>{text}</p>)}
            />
            <TextField
              name="passwordConfirmation"
              label="Confirm your new password"
              variant="outlined"
              type={hidePasssword ? 'password' : 'text'}
              autoComplete="off"
              autoFocus
              fullWidth
              margin={textFieldMargin}
              size={textFieldSize}
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                endAdornment: hideAdornments ? undefined : (
                  <InputAdornment position="end">
                    <i
                      style={styles.hidePassword}
                      className="material-icons"
                      onClick={() => setHidePasssword(!hidePasssword)}
                      onKeyPress={(e) =>
                        e.keyCode === 13 && setHidePasssword(!hidePasssword)
                      }
                      role="button"
                      tabIndex={-1}
                    >
                      {hidePasssword ? 'visibility' : 'visibility_off'}
                    </i>
                  </InputAdornment>
                ),
              }}
              inputRef={register({
                required: 'Required',
                validate: {
                  matchesPassword: (val) => val === getValues().password,
                },
              })}
              error={errors.passwordConfirmation != null}
              helperText={
                errors.passwordConfirmation &&
                (errors.passwordConfirmation.message ||
                  'Passwords do not match')
              }
            />
            <Button
              color="primary"
              type="submit"
              variant="contained"
              size={buttonSize}
              disabled={isSubmitting || formState.isSubmitting}
              sx={styles.formButtons}
            >
              RESET PASSWORD
            </Button>
          </FadeIn>
        </Card>
      </form>
    </Box>
  );
};

export default PasswordReset;
