import React from 'react';

import { Link } from 'react-router-dom';

import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

import { RecoveryCodeBatchView } from '@lastwall/credentials';

const RecoveryCodeSetupView = ({ recoveryCodes = [], error }) => {
  if (error) {
    return (
      <Card
        sx={{
          pl: 3,
          pr: 3,
          pt: 3,
          pb: 3,
          mb: 2,
          xs: {
            // 360px is the smallest viewport width of popular Android phones atm
            width: 360,
            pl: 2,
            pr: 2,
            pt: 1,
            pb: 2,
          },
        }}
      >
        <Typography variant="body1" color="error" paragraph>
          There was a problem generating recovery codes. Please try again.
        </Typography>
        {
          // @ts-ignore
          !window.isProduction && (
            <Typography variant="body2" color="error" paragraph>
              {`[DEV MODE ONLY] Error: ${error}`}
            </Typography>
          )
        }
      </Card>
    );
  }

  return (
    <Card
      sx={{
        pl: 3,
        pr: 3,
        pt: 3,
        pb: 3,
        mb: 2,
        xs: {
          // 360px is the smallest viewport width of popular Android phones atm
          width: 360,
          pl: 2,
          pr: 2,
          pt: 1,
          pb: 2,
        },
      }}
    >
      <Typography variant="h2" paragraph>
        Recovery Codes
      </Typography>
      <RecoveryCodeBatchView backupCodes={recoveryCodes}>
        <Button
          color="primary"
          size="small"
          type="submit"
          variant="contained"
          component={Link}
          to="/two-factor-setup"
        >
          Done
        </Button>
      </RecoveryCodeBatchView>
    </Card>
  );
};

export default RecoveryCodeSetupView;
