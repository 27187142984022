import React, { useEffect, useContext } from 'react';

import { useStateMachine } from 'little-state-machine';
import { useFetchGraphQL } from '../hooks/apiFetchOptions';
import RISCContext from '../providers/risc/RISCContext';

import SuccessView from './Success.view';
import { getLoggedInOrgProfile } from '../store/loggedInOrgProfileStore';
import { getLogoURL } from '../store/orgProfileStore';

const SuccessPage = () => {
  const { state } = useStateMachine() as any;
  const { getRiscData } = useContext(RISCContext);

  // successful login
  const createAuthAssertionMutation = `
    mutation($input: CreateAuthAssertionInput!) {
      createAuthAssertion(input: $input) {
        saml {
          postURL
          postData
          binding
        }
        oidc {
          redirectUri
        } 
      }
    }
  `;

  const submitSAMLAssertion: any = useFetchGraphQL(
    async ({ fetchGraphQL }) => {
      const loggedInOrgProfile = getLoggedInOrgProfile();
      const riscData = await getRiscData();

      const data = await fetchGraphQL({
        state,
        query: createAuthAssertionMutation,
        variables: {
          input: {
            loggedInCSRFToken: loggedInOrgProfile?.csrfToken,
            riscData: JSON.stringify(riscData),
          },
        },
      });

      // Uncomment to enable persistent logins

      // // Only set the logged in org profile on first log in.
      // if (loggedInOrgProfile == null) {
      //   localStorage.setItem('loggedInAs', JSON.stringify(state.orgProfile))
      // }

      // Save username for auto-population on next login
      try {
        // username may be blank on PIV/CAC login
        if (state.login.username) {
          localStorage.setItem('username', state.login.username);
        }
      } catch (e) {
        // console.log('Could not save username, localStorage is not enabled or is full');
      }

      const { oidc, saml } = data.createAuthAssertion;

      // Only one of these should be populated
      if (!!oidc && !!saml) {
        throw new Error('Invalid server response');
      } else if (oidc) {
        const { redirectUri } = oidc;
        window.location.href = redirectUri;
      } else if (saml) {
        const { postURL, postData, binding } = saml;

        // Handle a `REDIRECT` binding (i.e. redirects to the ACS route of the
        // SP with the SAML params in the query parameters).
        if (binding === 'REDIRECT') {
          const redirectQueryParams = new URLSearchParams(postData);
          window.location.href = `${postURL}?${redirectQueryParams.toString()}`;
        }

        // Handle a `POST` binding (i.e. POST to the ACS route of the SP with
        // SAML params in the POST body).
        if (binding === 'POST') {
          const form = document.createElement('form');

          const targetURL = postURL;

          form.action = targetURL;
          form.method = 'POST';

          Object.entries(postData).forEach(([key, value]) => {
            // eslint-disable-next-line @typescript-eslint/no-shadow
            const input = document.createElement('input');
            input.type = 'hidden';
            input.name = key;
            input.value = value as string;
            form.appendChild(input);
          });

          document.body.appendChild(form);
          form.submit();

          // Never resolve - the page is redirecting via form submission back to the SAML provider
          // eslint-disable-next-line
          await new Promise((_resolve) => {});
        }
      }
    },
    [state],
  );

  useEffect(submitSAMLAssertion.run, []);

  useEffect(() => {
    // Delete the persistent credentials on error to prevent users from being locked out of their
    // accounts until their CSRF expires.
    if (submitSAMLAssertion.error) {
      localStorage.removeItem('loggedInAs');
    }
  }, [submitSAMLAssertion.error]);

  const logoURL = getLogoURL(state.orgProfile);

  return (
    <SuccessView
      {...{
        logoURL,
        error: submitSAMLAssertion.error?.message,
        message: 'Logging In',
      }}
    />
  );
};

export default SuccessPage;
