import React, { createContext, useState, useEffect, useContext } from 'react';
import RiscDataCollector from './RiscDataCollector';

/* eslint-disable */
const RISCContext = createContext({
  initialize: (params) => {},
  addInputElement: (inputElement) => {},
  removeInputElement: (inputElement) => {},
  // eslint-disable-next-line @typescript-eslint/require-await
  getRiscData: async () => {},
  stopRisc: (clearCadence) => {},
});
/* eslint-enable */

export default RISCContext;

export const useRISCInputRef = (ref, enabled) => {
  const risc = useContext(RISCContext);

  useEffect(() => {
    if (!enabled) return () => {};

    const inputElement = ref.current;
    risc.addInputElement(inputElement);

    return () => risc.removeInputElement(inputElement);
  }, [enabled]);
};

export const RISCProvider = ({ children }) => {
  const [risc] = useState(() => new RiscDataCollector());

  useEffect(() => {
    risc.initialize({
      inputElements: [],
      serviceName: null,
      previousRiscInfo: null,
      riscField: 'riscdata',
      lastwallIframe: null,
    });

    return () => risc.stopRisc();
  }, []);

  return (
    <RISCContext.Provider value={risc}>
      <input
        id="riscdata"
        type="hidden"
        name="riscdata"
        value="custom risc data"
      />
      {children}
    </RISCContext.Provider>
  );
};
