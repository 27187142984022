import React from 'react';

import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';

type ErrorType = { message: string };

interface ErrorPageProps {
  error: ErrorType;
  title?: string;
}

const defaultTitle = 'Something has gone wrong:';

const ErrorPage = ({ error, title = defaultTitle }: ErrorPageProps) => {
  // eslint-disable-next-line no-console
  console.error(error);

  return (
    <Card style={{ padding: 16 }}>
      <Typography color="error" variant="h6">
        {title}
      </Typography>
      <Typography variant="body1">{error.message}</Typography>
    </Card>
  );
};

// Default props required by eslint even though provided in params above.
ErrorPage.defaultProps = {
  title: defaultTitle,
};

export default ErrorPage;
