export const loginAndPasswordCommonStyles = {
  formContainer: {
    marginLeft: 5,
    marginRight: 5,

    '& ::-ms-clear': {
      display: 'none !important',
    },
    '& ::-ms-reveal': {
      display: 'none !important',
    },
  },
  title: {
    textAlign: 'center',
    marginBottom: 3,
  },
  formButtons: {
    display: 'flex',
    flex: 0,
    justifyContent: 'space-between',
    marginTop: 4,
    marginBottom: 5,
  },
};

// @ts-ignore
export default {
  ...loginAndPasswordCommonStyles,
  addCedentialsWrapper: {
    marginBottom: 5,
    width: '40ch',
  },
  credentialsInstructions: {
    marginTop: 3,
  },
  actions: {
    display: 'flex',
    flex: 0,
    justifyContent: 'space-between',
    marginTop: 1,
    marginBottom: 5,
  },
  loginButton: {
    marginTop: 2,
  },
};
