import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useStateMachine } from 'little-state-machine';
import factorsDisplayText from '../../components/factor/factorsDisplayText';
import factorURL from '../../components/factor/factorURL';

const TwoFactorSelector = ({ factors }) => {
  const { state } = useStateMachine({}) as any;

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.only('xs'));
  const buttonSize = mobile ? 'small' : 'medium';

  return (
    <>
      <Button
        data-testid="two-factor-selector-button"
        variant="outlined"
        aria-controls="two-factor-selector"
        aria-haspopup="true"
        onClick={handleClick}
        sx={{ mt: 2 }}
        disabled={factors.length === 0}
        size={buttonSize}
      >
        Use other credential
      </Button>

      <Menu
        id="two-factor-selector"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: mobile ? 'top' : 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: mobile ? 'bottom' : 'top',
          horizontal: 'center',
        }}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {factors.map((factor) => (
          <MenuItem
            key={factor.type}
            component={Link}
            dense={mobile}
            to={factorURL(factor.type, { state })}
          >
            {factorsDisplayText[factor.type]?.title || factor.type}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default TwoFactorSelector;
