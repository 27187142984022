// username gets stored in localstorage on successful login
let username = '';
try {
  username = localStorage.getItem('username') || '';
} catch (e) {
  // console.log('localStorage is not enabled or is full');
}

const login = {
  username,
  password: '',
  authSessionSlug: null,
};

export const updateLogin = (state, payload) => ({
  ...state,
  login: {
    ...state.login,
    ...payload,
  },
});

export default login;
