import React from 'react';
import { useParams } from 'react-router-dom';

import { useStateMachine } from 'little-state-machine';
import { useFetchGraphQL } from '../core/hooks/apiFetchOptions';
import KillSwitchView from './KillSwitch.view';

const KillSwitchPage = () => {
  const { state } = useStateMachine();
  const { authSessionSlug, killSwitchToken } = useParams();

  // successful login
  const executeKillSwitchMutation = `
    mutation($input: ExecuteKillSwitchInput!) {
      executeKillSwitch(input: $input) {
        id
      }
    }
  `;

  const submitKillSwitch: any = useFetchGraphQL(
    async ({ fetchGraphQL }) => {
      await fetchGraphQL({
        state,
        query: executeKillSwitchMutation,
        variables: {
          input: {
            authSessionSlug,
            killSwitchToken,
          },
        },
      });
    },
    [state],
  );

  // useEffect(submitKillSwitch.run, [])

  return (
    <KillSwitchView
      {...{
        onSubmit: submitKillSwitch.run,
        error: submitKillSwitch.error?.message,
        isPending: submitKillSwitch.isPending,
        isSettled: submitKillSwitch.isSettled,
      }}
    />
  );
};

export default KillSwitchPage;
