interface Action {
  type: string;
  newOrgProfileError: OrgProfileError;
}

type OrgProfileError = {
  message: string;
};

export const reducer = (state: any, action: Action) => {
  switch (action.type) {
    case 'orgProfileErrorChange':
      return {
        ...state,
        orgProfileError: action.newOrgProfileError,
      };
    default:
      return {};
  }
};

export type Reducer = typeof reducer;
