/* eslint-disable */
import React, { createRef, useEffect } from 'react';

// Support
import { useForm } from 'react-hook-form';

// Global context

// Material
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { useRISCInputRef } from '../../core/providers/risc/RISCContext';

// Custom
import TwoFactorSelector from '../two-factor-selector/TwoFactorSelector';

const TwoFactorWebAuthnView = ({
  factors,
  pending = false,
  error = undefined,
  primary,
  defaultValues,
  onSubmit = () => {},
}) => {
  const { handleSubmit, register, errors, setError } = useForm({
    defaultValues,
  });

  const emailError = errors.email?.message ?? null;

  const { email } = defaultValues;

  const isSupported = true;

  // Check if browser is any of the browsers that do not support TextField
  // adornments.
  const ua = navigator.userAgent;
  const isPanGlobalProtect = ua.toLowerCase().includes('globalprotect');
  const isSalesforce = ua.toLowerCase().includes('salesforce');
  const hideAdornments = isPanGlobalProtect || isSalesforce;

  const emailRef: any = createRef();
  useRISCInputRef(emailRef, true);

  useEffect(() => {
    if (!pending && error) {
      setError('email', {
        type: 'server',
        message: error,
        shouldFocus: true,
      });
    }
  }, [error, pending]);

  return (
    <form
      // @ts-ignore
      onSubmit={handleSubmit((values) =>
        onSubmit({
          values: { email: primary ? values.email : email },
          setError,
        }),
      )}
    >
      <Box
        pl={4}
        pr={4}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center',
          mb: 5,
        }}
      >
        {!isSupported && (
          <>
            <Typography variant="h2" paragraph>
              WebAuthn Authentication
            </Typography>
            <Typography variant="body1" paragraph color="error">
              WebAuthn is not available on your platform. Please use a different
              credential.
            </Typography>
          </>
        )}
        {isSupported && (
          <>
            {pending && (
              <>
                <Typography variant="h2" paragraph>
                  Awaiting your authorization
                </Typography>
                <Typography
                  variant="body1"
                  paragraph
                  color="textPrimary"
                  sx={{ marginLeft: 2, marginRight: 2 }}
                >
                  Please tap or otherwise authorize your WebAuthn key
                </Typography>
              </>
            )}
            {!pending && (
              <>
                <Typography variant="h2" paragraph>
                  WebAuthn Authentication
                </Typography>
                <Typography variant="body1" paragraph>
                  Please plug in your WebAuthn key before pressing Authenticate
                </Typography>
              </>
            )}
          </>
        )}

        {primary && (
          <TextField
            label="Enter your email"
            variant="outlined"
            fullWidth
            autoFocus
            autoComplete="off"
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{ 'data-testid': 'email-field' }} // DOM input component props
            InputProps={{
              // React Input component props
              name: 'email',
              endAdornment: hideAdornments ? undefined : (
                <InputAdornment position="end">
                  <i className="material-icons">account_circle</i>
                </InputAdornment>
              ),
              sx: {
                WebkitUserSelect: 'text',
                userSelect: 'text',
              },
            }}
            inputRef={(e) => {
              emailRef.current = e;
              register(e, {
                required: 'Required',
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                  message: 'Malformed email address',
                },
              });
            }}
            error={!!emailError}
            helperText={emailError}
          />
        )}

        <Box mt={2} sx={{ width: 231 }}>
          <Button
            data-testid="authenticate-button"
            fullWidth
            color="primary"
            type="submit"
            variant="contained"
          >
            authenticate
          </Button>
          {!!emailError && !primary && (
            <Typography data-testid="button-error" paragraph color="error">
              {error}
            </Typography>
          )}
        </Box>

        <Box
          mt={3}
          sx={{ display: 'flex', direction: 'row', justifyContent: 'center' }}
        >
          <TwoFactorSelector
            factors={factors.filter((factor) => factor.type !== 'WEBAUTHN')}
          />
        </Box>
      </Box>
    </form>
  );
};

export default TwoFactorWebAuthnView;
