import React, { useEffect, useRef, useLayoutEffect, useState } from 'react';

import { useForm } from 'react-hook-form';

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Icon from '@mui/material/Icon';
import TextField from '@mui/material/TextField';

import { InputAdornment } from '@mui/material';
import TwoFactorSelector from '../two-factor-selector/TwoFactorSelector';

const TwoFactorTOTPView = ({
  factors,
  // failed,
  onSubmit = () => {},
  isSubmitting = false,
  error: externalError = null,
}) => {
  const { handleSubmit, register, errors, setError, formState, reset } =
    useForm();

  // Check if browser is any of the browsers that do not support TextField
  // adornments.
  const ua = navigator.userAgent;
  const isPanGlobalProtect = ua.toLowerCase().includes('globalprotect');
  const isSalesforce = ua.toLowerCase().includes('salesforce');
  const hideAdornments = isPanGlobalProtect || isSalesforce;

  const [hideTOTPCode, setTOTPCode] = useState(true);

  useEffect(() => {
    if (!isSubmitting && externalError) {
      reset();
      setError('code', {
        type: 'server',
        message: externalError,
        shouldFocus: true,
      });
    }
  }, [externalError, isSubmitting]);

  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.only('xs'));
  const textFieldMargin = mobile ? 'dense' : 'normal';
  const textFieldSize = mobile ? 'small' : 'medium';
  const buttonSize = textFieldSize;

  const codeRef: any = useRef();
  useLayoutEffect(() => codeRef.current.focus(), []);

  // if (failed) {
  //   return (
  //     <Typography variant="body1" paragraph>
  //       Failed 2FA too many times. Please restart and try again.
  //     </Typography>
  //   )
  // }

  return (
    <form
      // @ts-ignore
      onSubmit={handleSubmit((values) => onSubmit({ values, setError }))}
    >
      <Card
        sx={{
          pl: 5,
          pr: 5,
          pt: 3,
          pb: 3,
          width: 400,
          xs: {
            // 360px is the smallest viewport width of popular Android phones atm
            width: 360,
            pl: 2,
            pr: 2,
            pt: 1,
            pb: 2,
          },
          textAlign: 'center',
        }}
      >
        <Typography variant="body1" paragraph={!mobile} gutterBottom={mobile}>
          <Icon fontSize="inherit">phonelink_lock</Icon>
          Enter your TOTP code below
        </Typography>

        <TextField
          name="code"
          label="Enter 2FA code"
          placeholder="123456"
          variant="outlined"
          autoComplete="off"
          type={hideTOTPCode ? 'password' : 'text'}
          autoFocus
          fullWidth
          margin={textFieldMargin}
          size={textFieldSize}
          InputProps={{
            autoFocus: true,
            endAdornment: hideAdornments ? (
              <></>
            ) : (
              <InputAdornment position="end" sx={{}}>
                {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
                <i
                  style={{ cursor: 'pointer' }}
                  className="material-icons"
                  onClick={() => setTOTPCode(!hideTOTPCode)}
                  role="button"
                  tabIndex={0}
                >
                  {hideTOTPCode ? 'visibility' : 'visibility_off'}
                </i>
              </InputAdornment>
            ),
          }}
          InputLabelProps={{
            shrink: true,
          }}
          inputRef={(e) => {
            codeRef.current = e;
            register(e, {
              required: 'Required',
              maxLength: {
                value: 8,
                message: 'Must be less then 9 digits',
              },
              pattern: {
                value: /^\d+$/,
                message: 'Invalid 2FA Code',
              },
            });
          }}
          error={errors.code != null}
          helperText={errors.code?.message}
        />

        <Button
          sx={{ mt: 2 }}
          color="primary"
          size={buttonSize}
          type="submit"
          variant="contained"
          disabled={isSubmitting || formState.isSubmitting}
        >
          Authenticate
        </Button>
        <TwoFactorSelector factors={factors.filter((x) => x.type !== 'TOTP')} />
      </Card>
    </form>
  );
};

export default TwoFactorTOTPView;
