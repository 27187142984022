import React, { useState, useEffect } from 'react';
import QRCode from 'qrcode';
import { useStateMachine } from 'little-state-machine';
import { getLogoURL } from '../core/store/orgProfileStore';
import IalSetupView from './IalSetup.view';
import defaultBackground from '../../assets/img/background.jpeg';
import { useFetchGraphQL } from '../core/hooks/apiFetchOptions';
import useNavigateToNext from '../core/hooks/useNavigateToNext';
import stageQueryFragment from '../core/store/stage/stageQuery.fragment';
/**
 * A handler, used by `little-state-machine` to update the orgProfile in local
 * storage. Taken directly out of the `little-state-machine` README
 * (https://github.com/beekai-oss/little-state-machine/blob/master/README.md#-example)
 */
function updateOrgProfile(state, payload) {
  return {
    ...state,
    orgProfile: {
      ...state.orgProfile,
      ...payload,
    },
  };
}

const QrCodeJwtQuery = `
  query {
    getUserIalJwt {
      jwt
      ${stageQueryFragment}
    }
  }
`;

const IalSetupPage = () => {
  const [token, setToken] = useState<string | undefined>(undefined);
  const [qrcode, setQrCode] = useState<string | undefined>(undefined);
  const navigateToNext = useNavigateToNext();

  // Set up `little-state-machine` so that the orgProfile can be updated. The
  // orgProfile stored by `little-state-machine` is used by the SSO frontend to
  // determine the background branding.
  const { actions, state } = useStateMachine({
    updateOrgProfile,
  }) as any;

  const { stage } = state;

  const getUserIalJwt = useFetchGraphQL(
    async ({ fetchGraphQL }) => {
      const data = await fetchGraphQL({
        state,
        query: QrCodeJwtQuery,
      });
      setToken(data.getUserIalJwt.jwt);
      if (data.getUserIalJwt.stage.state !== 'NEEDS_IDENTITY_PROOFING') {
        navigateToNext({ stage: data.getUserIalJwt.stage });
      }
    },
    [state],
  );

  useEffect(() => {
    getUserIalJwt.run();
  }, [getUserIalJwt.run]);

  // Generate QR Code for token
  useEffect(() => {
    (async () => {
      const qrCode = await QRCode.toDataURL(token, { type: 'svg' });
      setQrCode(qrCode);
    })();
  }, [token]);

  // Add hardcoded "blocks" as the background.
  useEffect(() => {
    actions.updateOrgProfile({
      backgroundURL: defaultBackground,
    });
  }, []);

  // Get logo from state machine
  const logoURL = getLogoURL(state.orgProfile);

  return (
    <IalSetupView
      qrcode={qrcode}
      logoURL={logoURL}
      clickAction={() => navigateToNext({ stage })}
    />
  );
};

export default IalSetupPage;
