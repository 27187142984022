import React from 'react';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import CardWithLogo from '../../components/card/CardWithLogo';

const FrictionView = ({ logoURL, message }) => (
  <CardWithLogo logoURL={logoURL}>
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Typography
        variant="body2"
        paragraph
        sx={{
          marginTop: 4,
          marginBottom: 5,
          textAlign: 'center',
        }}
      >
        {message}
      </Typography>
    </Box>
  </CardWithLogo>
);

export default FrictionView;
