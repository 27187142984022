import React, { useEffect, useState } from 'react';
import { useStateMachine } from 'little-state-machine';

import { useFetchGraphQL } from '../core/hooks/apiFetchOptions';

import LogoutView from './Logout.view';

// Import default logo.
// @ts-ignore
import lastwallLogo from '../../assets/img/lw-logo.svg';

import defaultBackground from '../../assets/img/background.jpeg';

const logoutBrandingQuery = `
  query ($serviceSlug: ID!) {
    logoutBranding(serviceSlug: $serviceSlug) {
      background {
        content
      }
      logo {
        content
      }
      signInURL
    }
  }
`;

/**
 * A handler, used by `little-state-machine` to update the orgProfile in local
 * storage. Taken directly out of the `little-state-machine` README
 * (https://github.com/beekai-oss/little-state-machine/blob/master/README.md#-example)
 */
function updateOrgProfile(state, payload) {
  return {
    ...state,
    orgProfile: {
      ...state.orgProfile,
      ...payload,
    },
  };
}

const LogoutPage = () => {
  const { search } = window.location;
  const query = new URLSearchParams(search);
  const serviceSlug = query.get('serviceID');
  const logoutReason = query.get('logoutReason');

  // Set up state to store logo and signin urls.
  const [signInUrl, setSignInUrl] = useState<URL | undefined>(undefined);
  const [logoUrl, setLogoUrl] = useState<URL | undefined>(undefined);

  // Set up `little-state-machine` so that the orgProfile can be updated. The
  // orgProfile stored by `little-state-machine` is used by the SSO frontend to
  // determine the background branding.
  const { actions, state } = useStateMachine({
    updateOrgProfile,
  }) as any;

  // Query the `logoutBranding` resolver and update related branding fields.
  const queryLogoutBrandingCallback = useFetchGraphQL(
    async ({ fetchGraphQL }) => {
      const { logoutBranding } = await fetchGraphQL({
        state,
        query: logoutBrandingQuery,
        variables: {
          serviceSlug,
        },
      });

      if (logoutBranding?.background) {
        actions.updateOrgProfile({ background: logoutBranding.background });
      } else {
        actions.updateOrgProfile({ backgroundURL: defaultBackground });
      }

      if (logoutBranding?.logo) {
        setLogoUrl(logoutBranding.logo.content);
      } else {
        setLogoUrl(lastwallLogo);
      }

      if (logoutBranding?.signInURL) {
        setSignInUrl(logoutBranding.signInURL);
      }
    },
    [],
  );

  // Request and update branding.
  useEffect(() => {
    if (serviceSlug) {
      queryLogoutBrandingCallback.run();
    }
  }, [serviceSlug]);

  return (
    <LogoutView
      serviceSignInURL={signInUrl}
      logoURL={logoUrl}
      logoutReason={logoutReason}
    />
  );
};

export default LogoutPage;
