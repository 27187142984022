import React from 'react';
import { Link } from 'react-router-dom';

import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';

import { useForm } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import CardWithLogo from '../../components/card/CardWithLogo';

const WebauthnSetupView = ({
  logoURL,
  error = null,
  webAuthnState = 'getUserConsent',
  onGetCredentialOptions = () => {},
  onFinished = () => {},
  onFinishError = null,
}) => {
  const isSupported = typeof window.PublicKeyCredential === 'function';

  const { handleSubmit, register, errors } = useForm();

  return (
    <form
      // @ts-ignore
      onSubmit={handleSubmit((values) => onFinished({ values }))}
    >
      <CardWithLogo logoURL={logoURL}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
            marginBottom: 5,
            marginLeft: 2,
            marginRight: 2,
          }}
        >
          {!isSupported && (
            <>
              <Typography variant="h2" paragraph>
                WebAuthn Authentication
              </Typography>
              <Typography variant="body1" paragraph color="error">
                WebAuthn is not available on your platform. Please use a
                different credential.
              </Typography>
            </>
          )}
          {isSupported && (
            <>
              {webAuthnState === 'getUserConsent' && (
                <>
                  <Typography variant="body1" paragraph>
                    Plug in your WebAuthn key before pressing
                    {error ? ' retry' : ' continue'}
                  </Typography>
                  {error && (
                    <Typography variant="body1" paragraph color="error">
                      {error}
                    </Typography>
                  )}

                  <Button
                    color="primary"
                    onClick={onGetCredentialOptions}
                    variant="contained"
                  >
                    {error ? 'Retry' : 'Continue'}
                  </Button>
                </>
              )}
              {webAuthnState === 'getAttestation' && (
                <>
                  <Typography variant="h2" paragraph>
                    Awaiting your authorization
                  </Typography>
                  <Typography variant="body1" paragraph color="textPrimary">
                    Please tap or otherwise authorize your WebAuthn key
                  </Typography>
                </>
              )}
              {webAuthnState === 'getNickname' && (
                <>
                  <Typography variant="h2" paragraph>
                    WebAuthn Key Setup
                  </Typography>
                  <TextField
                    name="nickname"
                    variant="outlined"
                    fullWidth
                    margin="dense"
                    placeholder="eg. Yubikey/TouchID"
                    inputRef={register({
                      required: 'Required',
                    })}
                    error={errors.nickname != null}
                    helperText={errors.nickname?.message}
                  />
                  <Typography variant="body1" paragraph>
                    Add a descriptive name for the key and press
                    {onFinishError ? ' retry' : ' continue'}
                  </Typography>
                  {onFinishError && (
                    <Typography variant="body1" paragraph color="error">
                      {onFinishError}
                    </Typography>
                  )}

                  <Button color="primary" type="submit" variant="contained">
                    {onFinishError ? 'Retry' : 'Continue'}
                  </Button>
                </>
              )}
            </>
          )}
        </Box>
      </CardWithLogo>
      <Button
        sx={{ mt: 2 }}
        variant="contained"
        component={Link}
        to="/two-factor-setup"
      >
        Start Over
      </Button>
    </form>
  );
};

export default WebauthnSetupView;
