import React, { useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useStateMachine } from 'little-state-machine';

import TermsAndConditionsView from './TermsAndConditions.view';

import { updateLogin } from '../../core/store/loginStore';
import { updateStage } from '../../core/store/stage/stageStore';
import useNavigateToNext from '../../core/hooks/useNavigateToNext';
import { getLogoURL } from '../../core/store/orgProfileStore';

const TermsAndConditionsPage = () => {
  const { state } = useStateMachine({
    updateLogin,
    updateStage,
  }) as any;

  const { orgProfile, stage } = state;

  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    history.push(location);
  }, []);

  const navigateToNext = useNavigateToNext();

  const navigateToFirstFactor = () => navigateToNext({ stage });

  useEffect(() => {
    if (!orgProfile.loaded || orgProfile.enableTermsAndConditions) {
      return;
    }

    navigateToFirstFactor();
  }, [orgProfile]);

  const { termsAndConditionsMarkdown, termsAndConditionsAcceptButtonText } =
    orgProfile;

  const logoURL = getLogoURL(orgProfile);

  return (
    <TermsAndConditionsView
      {...{
        logoURL,
        acceptTerms: navigateToFirstFactor,
        termsAndConditionsMarkdown,
        termsAndConditionsAcceptButtonText,
      }}
    />
  );
};

export default TermsAndConditionsPage;
