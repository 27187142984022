import React from 'react';

import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import { SxProps, Theme } from '@mui/material/styles';

type CardWithLogoProps = {
  children: any;
  logoURL: string;
  sx: SxProps<Theme>;
};

const CardWithLogo = ({ children, logoURL, sx = [] }: CardWithLogoProps) => (
  <Card
    sx={[
      {
        display: 'flex',
        width: 400,
        xs: {
          /* 360px is the smallest viewport width of popular Android phones atm */
          width: 360,
        },
        flexDirection: 'column',
        justifyContent: 'center',
        flexShrink: 0,
      },
      ...(Array.isArray(sx) ? sx : [sx]),
    ]}
  >
    <CardMedia
      sx={{
        height: 160,
        backgroundSize: 'contain',
        mb: 2,
        ml: 5,
        mr: 5,
      }}
      image={logoURL}
    />
    {children}
  </Card>
);

export default CardWithLogo;
