import React, { useMemo, useState, useEffect } from 'react';
import { useStateMachine } from 'little-state-machine';
import { useLocation } from 'react-router-dom';

import { GetOrgView } from './GetOrg.view';
import getUserFromFormVals from './utils';

import { useFetchGraphQL } from '../core/hooks/apiFetchOptions';

import backgroundImage from '../../assets/img/background.jpeg';
import { getLogoURL } from '../core/store/orgProfileStore';

/**
 * A handler, used by `little-state-machine` to update the orgProfile in local
 * storage. Taken directly out of the `little-state-machine` README
 * (https://github.com/beekai-oss/little-state-machine/blob/master/README.md#-example)
 */
function updateOrgProfile(state, payload) {
  return {
    ...state,
    orgProfile: {
      ...state.orgProfile,
      ...payload,
    },
  };
}

const GetOrgPage = () => {
  // Set up `little-state-machine` so that the orgProfile can be updated. The
  // orgProfile stored by `little-state-machine` is used by the SSO frontend to
  // determine the background branding.
  const { actions, state } = useStateMachine({
    updateOrgProfile,
  }) as any;

  const { search } = useLocation();
  const [orgError, setOrgError] = useState('');

  const SAMLRequest = useMemo(() => {
    const query = new URLSearchParams(search);
    const request = Object.fromEntries(query.entries()).SAMLRequest;
    return encodeURIComponent(request);
  }, [search]);

  useEffect(() => {
    // Add hardcoded "blocks" as the background.
    actions.updateOrgProfile({
      backgroundURL: backgroundImage,
    });
  }, []);

  const handleSubmitForm = useFetchGraphQL(async ({ args, fetchGraphQL }) => {
    const username = getUserFromFormVals(args[0]);

    const getOrgMutation = `
      mutation($input: GetOrgInput!) {
        getOrg(input: $input) {
          slug
          profileId
        }
      }
    `;

    setOrgError('');
    try {
      const data = await fetchGraphQL({
        state,
        query: getOrgMutation,
        variables: {
          input: {
            username,
          },
        },
      });

      const { slug, profileId } = data.getOrg;

      // @ts-ignore
      const baseUrl = window.isProduction
        ? `https://${window.location.hostname}`
        : `http://${window.location.hostname}:4001`;
      const url = `${baseUrl}/saml2/${slug}/${profileId}/login?SAMLRequest=${SAMLRequest}`;

      // we want to override the username saved on successful login in case they
      // entered a different one
      localStorage.setItem('username', username);

      window.location.href = url;
    } catch (e) {
      setOrgError('An error has occurred');
    }
  }, []);

  const logoURL = getLogoURL(state.orgProfile);
  return (
    <GetOrgView
      onSubmit={handleSubmitForm.run}
      logoURL={logoURL}
      serverError={orgError}
    />
  );
};

export default GetOrgPage;
