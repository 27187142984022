import React from 'react';
import { useHistory } from 'react-router-dom';
import { useStateMachine } from 'little-state-machine';
import { useSnackbar } from 'notistack';

import TwoFactorPIVView from './PIVSetup.view';
import { useFetchGraphQL } from '../../core/hooks/apiFetchOptions';
import { updateStage } from '../../core/store/stage/stageStore';
import stageQueryFragment from '../../core/store/stage/stageQuery.fragment';
import { generatePivSubdomain } from '../../core/piv/utils';

const PIVSetupPage = () => {
  const snackbar = useSnackbar();
  const history = useHistory();

  const { state, actions } = useStateMachine({
    updateStage,
  }) as any;

  const createStagesMutation = `
    mutation {
      createStages {
        ${stageQueryFragment}
      }
    }
  `;

  const fetchPIV = useFetchGraphQL(async ({ fetchGraphQL, signal }) => {
    const { pivDomain, csrfToken, authSessionSlug } = state.orgProfile;

    const subdomain = generatePivSubdomain(authSessionSlug);

    const url =
      `https://${subdomain}.${pivDomain}/api/v2/add-piv-credential?` +
      `csrfToken=${encodeURIComponent(csrfToken)}`;

    const res = await fetch(url, {
      method: 'POST',
      mode: 'cors',
      credentials: 'include',
      // headers: {
      //   authorization: `Bearer ${csrfToken}`,
      // },
      signal,
    });

    try {
      const json = await res.json();

      if (!res.ok || (json.errors !== undefined && json.errors.length > 0)) {
        throw new Error(json.errors[0].message);
      }
    } catch (e) {
      throw new Error('An error occurred. Please try again');
    }

    const data = await fetchGraphQL({
      variables: {},
      state,
      query: createStagesMutation,
    });
    actions.updateStage(data.createStages);

    snackbar.enqueueSnackbar('PIV/CAC registration successful!');
    history.push('/two-factor-setup');
  }, []);

  return (
    <TwoFactorPIVView
      {...{
        logoURL: state.orgProfile.logoURL,
        isPending: fetchPIV.isPending,
        error: fetchPIV.error?.message,
        onSubmit: fetchPIV.run,
      }}
    />
  );
};

export default PIVSetupPage;
