import React, { useContext } from 'react';
import { useStateMachine } from 'little-state-machine';

import UserSelectionView from './UserSelecton.view';
import useNavigateToNext from '../../core/hooks/useNavigateToNext';
import { useFetchGraphQL } from '../../core/hooks/apiFetchOptions';
import stageQueryFragment from '../../core/store/stage/stageQuery.fragment';
import RISCContext from '../../core/providers/risc/RISCContext';

const UserSelectionPage = () => {
  const navigateToNext = useNavigateToNext();
  const { getRiscData } = useContext(RISCContext);

  const { state } = useStateMachine() as any;
  const { factorAuthorizationTokens } = state.stage;

  const consumeFATokenMutation = `
    mutation($input: ConsumeFactorAuthorizationTokenInput!) {
      consumeFactorAuthorizationToken(input: $input) {
        ${stageQueryFragment}
      }
    }
  `;

  const consumeFAToken: any = useFetchGraphQL(
    async ({ args, fetchGraphQL }) => {
      const riscData = await getRiscData();
      const tokenID = args[0];

      const data = await fetchGraphQL({
        state,
        query: consumeFATokenMutation,
        variables: {
          input: {
            tokenID,
            riscData: JSON.stringify(riscData),
          },
        },
      });

      navigateToNext(data.consumeFactorAuthorizationToken);
    },
    [state],
  );

  const { error } = consumeFAToken;

  return (
    <UserSelectionView
      {...{
        factorAuthorizationTokens,
        consumeToken: consumeFAToken.run,
        isPending: consumeFAToken.isPending,
        error: error?.message,
        logoURL: state.orgProfile.logoURL,
      }}
    />
  );
};

export default UserSelectionPage;
