import ReactDOM from 'react-dom';
import { useHistory } from 'react-router-dom';
import { useStateMachine } from 'little-state-machine';
import { updateStage } from '../store/stage/stageStore';
import factorURL from '../../components/factor/factorURL';

export const onAuthorizedToLogin = ({ history }) => {
  // successful login
  history.push('/success');
};

const useNavigateToNext = () => {
  const history = useHistory();
  const { state, actions } = useStateMachine({
    updateStage,
  }) as any;

  const navigateToNext = (json) => {
    ReactDOM.unstable_batchedUpdates(() => {
      actions.updateStage(json);

      const { stage } = json;

      const resetRequired = stage.passwordResetRequired;

      // console.log({ res, onboarding, stage, passwordReset })
      const authorizedLogin = stage.state === 'AUTHORIZED_TO_LOGIN';
      const authorizedToAdd = stage.state === 'AUTHORIZED_TO_ADD_CREDENTIALS';
      const authorizedForAny = authorizedLogin || authorizedToAdd;
      const needsIdentityProofing = stage.state === 'NEEDS_IDENTITY_PROOFING';

      if (stage.factorAuthorizationTokens.length > 0) {
        history.push('/user-selection');
      } else if (stage.enforceLoginFriction) {
        history.push('/friction');
      } else if (needsIdentityProofing) {
        history.push('/ial-setup');
      } else if (authorizedForAny && resetRequired) {
        history.push('/password-reset');
      } else if (
        authorizedToAdd ||
        (authorizedLogin && stage.forceOnboarding)
      ) {
        history.push('/two-factor-setup');
      } else if (authorizedLogin) {
        onAuthorizedToLogin({ history });
      } else {
        const defaultFactor = stage.factors.find((factor) => factor.default);

        const nextFactor = defaultFactor || stage.factors[0];

        history.push(factorURL(nextFactor.type, { state }));
      }
    });
  };

  return navigateToNext;
};

export default useNavigateToNext;
