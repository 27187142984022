const bufferToBase64 = (buf: ArrayBuffer): string => {
  const bufferArray = new Uint8Array(buf);
  const binstr = Array.prototype.map
    .call(bufferArray, (c) => String.fromCharCode(c))
    .join('');
  return btoa(binstr);
};

// Gets all enumerable [k, v] pairs in the Object and it's prototype chain
const prototypeEntries = (data: any) => {
  const entries = [];

  // eslint-disable-next-line no-restricted-syntax, guard-for-in
  for (const k in data) {
    // eslint-disable-next-line security/detect-object-injection
    entries.push([k, data[k]]);
  }

  return entries.filter(([, v]) => typeof v !== 'function');
};

/* eslint-disable @typescript-eslint/no-explicit-any */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const publicKeyCredentialToJSON = (data: any): any => {
  if (data instanceof Array) {
    return data.map(publicKeyCredentialToJSON);
  }

  if (data instanceof ArrayBuffer) {
    return bufferToBase64(data);
  }

  /**
   * Generally the rawId field of the credentials object returned from navigator.credentials.create() as an ArrayBuffer,
   * but for some reason the bitwarden chrome extension returns a Uint8Array.
   *
   * Because typed arrays are an "instanceof Object", we need to check for this type
   * before recursing over its object properties as it would do in the next block.
   *
   * If the data object is a view, then we can simply convert its buffer contents to base64 as above.
   **/
  if (ArrayBuffer.isView(data)) {
    return bufferToBase64(data.buffer);
  }

  if (data instanceof Object) {
    const entries = prototypeEntries(data).map(([k, v]) => [
      k,
      publicKeyCredentialToJSON(v),
    ]);
    return Object.fromEntries(entries);
  }

  return data;
};

export const base64ToBuffer = (base64: string): ArrayBuffer => {
  const binstr = atob(base64);
  const charCodes = Array.prototype.map.call(binstr, (c) => c.charCodeAt(0));
  return Uint8Array.from(charCodes as number[]);
};
