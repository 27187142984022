import React, { useEffect, useRef, useLayoutEffect } from 'react';

import { useForm } from 'react-hook-form';

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Icon from '@mui/material/Icon';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';

import TwoFactorSelector from '../two-factor-selector/TwoFactorSelector';

const TwoFactorSMSView = ({
  factors,
  failed = false,
  onSubmit = () => {},
  isSubmitting = false,
  onResendClick = () => {},
  isResending = false,
  error: externalError = null,
}) => {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.only('xs'));
  const textFieldMargin = mobile ? 'dense' : 'normal';
  const textFieldSize = mobile ? 'small' : 'medium';
  const buttonSize = textFieldSize;

  const { handleSubmit, register, errors, setError, formState, reset } =
    useForm();

  useEffect(() => {
    if (!isSubmitting && externalError) {
      reset();
      setError('code', {
        type: 'server',
        message: externalError,
        shouldFocus: true,
      });
    }
  }, [externalError, isSubmitting]);

  const codeRef: any = useRef();
  useLayoutEffect(() => codeRef.current.focus(), []);

  if (failed) {
    return (
      <Typography variant="body1" paragraph>
        Failed 2FA too many times. Please restart and try again.
      </Typography>
    );
  }

  return (
    <form
      // @ts-ignore
      onSubmit={handleSubmit((values) => onSubmit({ values, setError }))}
    >
      <Card
        sx={{
          pl: 5,
          pr: 5,
          pt: 3,
          pb: 3,
          width: 400,
          xs: {
            // 360px is the smallest viewport width of popular Android phones atm
            width: 360,
            pl: 2,
            pr: 2,
            pt: 1,
            pb: 2,
          },
          textAlign: 'center',
        }}
      >
        <Typography variant="body1" paragraph={!mobile} gutterBottom={mobile}>
          <Icon fontSize="inherit">phonelink_lock</Icon>
          Enter your SMS code below
        </Typography>

        <TextField
          name="code"
          label="Enter 2FA code"
          placeholder="123456"
          variant="outlined"
          autoComplete="off"
          autoFocus
          fullWidth
          margin={textFieldMargin}
          size={textFieldSize}
          InputLabelProps={{
            shrink: true,
          }}
          inputRef={(e) => {
            codeRef.current = e;
            register(e, {
              required: 'Required',
              maxLength: {
                value: 8,
                message: 'Must be less then 9 digits',
              },
              pattern: {
                value: /^\d+$/,
                message: 'Invalid 2FA Code',
              },
            });
          }}
          error={errors.code != null}
          helperText={errors.code?.message}
        />

        <Box
          sx={{
            mt: 2,
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Button
            variant="outlined"
            size={buttonSize}
            disabled={isSubmitting || formState.isSubmitting || isResending}
            onClick={onResendClick}
          >
            Resend Code
          </Button>

          <Button
            size={buttonSize}
            color="primary"
            type="submit"
            variant="contained"
            disabled={isSubmitting || formState.isSubmitting}
          >
            Authenticate
          </Button>
        </Box>
        <TwoFactorSelector factors={factors.filter((x) => x.type !== 'SMS')} />
      </Card>
    </form>
  );
};

export default TwoFactorSMSView;
