import React from 'react';
import { Link } from 'react-router-dom';

import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import Icon from '@mui/material/Icon';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

import CardWithLogo from '../../components/card/CardWithLogo';

const PIVSetupView = ({ error, onSubmit = () => {}, logoURL, isPending }) => {
  const tooltip = `\
    Your PIV/CAC holds up to 4 digital certificates.
    The required certificate can be identified by the certificate Field named "Key Usage".
    Key Usage will be for a single use only.
    Key Usage value will be one of: "Digital Signature" or "Signing".\
  `;

  const isSupported = true;

  return (
    <Box>
      <CardWithLogo logoURL={logoURL}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
            mb: 5,
          }}
        >
          <Typography variant="h2" paragraph>
            {!isPending && 'PIV Card Setup'}
            {isPending && 'Awaiting PIV Authentication'}
          </Typography>
          {!isSupported && (
            <Typography variant="body1" paragraph color="error">
              CAC/PIV is not available on your platform. Please use a different
              credential.
            </Typography>
          )}
          {isSupported && (
            <>
              {!isPending && (
                <>
                  <Typography variant="body1" paragraph>
                    PIV/Authentication certificate must be used.
                    <br />
                    All other certificates will be rejected.
                    <Tooltip title={tooltip} aria-label="help">
                      <Icon fontSize="inherit">help_outline</Icon>
                    </Tooltip>
                  </Typography>
                  <Typography variant="body1" paragraph>
                    Plug in your PIV/CAC card before pressing continue
                  </Typography>

                  <Button
                    variant="contained"
                    color="primary"
                    onClick={onSubmit}
                  >
                    Continue with PIV/CAC
                  </Button>

                  {error && <Typography color="error">{error}</Typography>}
                </>
              )}
              {isPending && (
                <>
                  <CircularProgress size={100} sx={{ mt: 2, mb: 4 }} />
                  <Typography variant="body1" paragraph>
                    Please authorize your PIV/CAC credentials
                  </Typography>
                  <Button variant="outlined" onClick={onSubmit}>
                    Retry
                  </Button>
                </>
              )}
            </>
          )}
        </Box>
      </CardWithLogo>
      <Button
        sx={{ mt: 2 }}
        variant="contained"
        component={Link}
        to="/two-factor-setup"
      >
        Start Over
      </Button>
    </Box>
  );
};

export default PIVSetupView;
