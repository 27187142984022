import React from 'react';
import { useStateMachine } from 'little-state-machine';
import Box from '@mui/material/Box';

// Custom styling props for internet explorer
const isIE = (window.document as any).documentMode;
let ieStyling = {};
if (isIE) {
  ieStyling = {
    height: '100vh',
  };
}

const Centered = ({ children }) => {
  const { state } = useStateMachine();
  // @ts-ignore
  const { background, backgroundURL } = state?.orgProfile;

  // If a background object is supplied, generate a base64 formatted url from
  // it, else use the backgroundURL which points to the Lastwall default 'local'
  // file.
  const bgUrl = background ? background.content : backgroundURL;

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        minHeight: '100vh',
        alignItems: 'center',
        justifyContent: 'center',
        paddingTop: 2,
        paddingBottom: 2,
        xs: {
          paddingTop: 0,
          paddingBottom: 0,
        },
        backgroundSize: 'cover',
        ...ieStyling,
      }}
      style={{ backgroundImage: bgUrl && `url(${bgUrl})` }}
    >
      {children}
    </Box>
  );
};

export default Centered;
